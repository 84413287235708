import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import TabDetails from "./TabsDetails";
import { makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const DialogDetails = ({ open, onClose, value }) => {
  const classes = useStyles();
  const dataPopup = useSelector(state => state.remindRedux.gantChat);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xl">
      <Box className={classes.iconTitle}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.titleDialog}>
        <Typography component="p" className={classes.titleDialogSub}>
          {dataPopup?.substation.name}
        </Typography>
        {dataPopup && (
          <Typography component="h2" className={classes.description}>{`Được tạo vào ngày ${format(
            dataPopup?.substation?.created * 1000,
            "HH:mm MM/dd/yyyy",
          )}`}</Typography>
        )}
        <TabDetails onClose={onClose} valueTask={value} />
      </Box>
    </Dialog>
  );
};

export default DialogDetails;

const useStyles = makeStyles(theme => ({
  iconTitle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  titleDialog: {
    padding: "0 51px",
    height: 810,
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",

      height: "100%",
    },
  },
  description: {
    color: "#ccc",
    fontSize: 12,
    marginBottom: 10,
  },
  titleDialogSub: {
    fontSize: 24,
    color: "#2E2E2E",
    fontWeight: 700,
  },
}));
