import { format as dateFormat, format } from "date-fns";
import { AppConstant } from "../const";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export const hasLogin = () => Boolean(Cookies.get(AppConstant.KEY_TOKEN));
export const handlingLogin = data => {
  Cookies.set(AppConstant.KEY_TOKEN, data.access_token);
  Cookies.set(AppConstant.KEY_USER_ID, data.username);
  Cookies.set(AppConstant.ACCOUNT_ID, data.user.id);
  Cookies.set(AppConstant.ROLE_ID, data.user.role.id);
};

export const handlingLogout = () => {
  Cookies.remove(AppConstant.KEY_TOKEN);
  Cookies.remove(AppConstant.KEY_USER_ID);
  Cookies.remove(AppConstant.ACCOUNT_ID);
};

export const snakeToCamelCase = str =>
  str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const camelToSnakeCase = str =>
  (str.charAt(0).toLowerCase() + str.slice(1) || str).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const toCamel = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = snakeToCamelCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toCamel(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const toSnake = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toSnake(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = camelToSnakeCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toSnake(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const isArrayNotEquals = (firstArray, secondArray) =>
  !firstArray ||
  !secondArray ||
  (Array.isArray(firstArray) && JSON.stringify(firstArray) !== JSON.stringify(secondArray));

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .replace(/-/g, "");
};

export const textNormalize = str => {
  return removeVietnameseTones(str).toLowerCase();
};

export const getFilterArray = (memberArray, search) => {
  let filterItems = [];
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(member => textNormalize(member.name).includes(textNormalize(search)));
  }
  return filterItems;
};

export const removeVietnameseTones = str => {
  if (!str) return "";
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
};

export const useDebounce = (value, delay) => {
  const [deboundceValue, setDeboundceValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDeboundceValue(value), delay);
    return () => clearTimeout(handler);
  }, [value]);

  return deboundceValue;
};

export const convertMillisecondsToDate = (timeStamp, formatConstant) => {
  let resultDate;
  if (formatConstant) {
    resultDate = dateFormat(new Date(timeStamp), formatConstant);
  } else {
    resultDate = new Date(timeStamp);
  }
  return resultDate;
};

export const getCreateTimeObject = data => {
  if (data) {
    let obj = {};
    data.forEach(element => {
      let time = format(element.start_time * 1000, "MM/yyyy");
      if (!obj[time]) {
        obj[time] = [];
      }
      obj[time].push(element);
    });
    return obj;
  }
};
