import { call, put, select } from "redux-saga/effects";
import { ApiConstant } from "../const";
import { LoginService } from "../services";
import LoginAction from "../redux/login.redux";
import { handlingLogin } from "../utils";

export function* checkLogin(action) {
  try {
    const { data } = action;

    let response = yield call(LoginService.checkLogin, data);

    let dataLogin = response.data.data;

    if (response.status === 200 && dataLogin) {
      handlingLogin(dataLogin);
      yield put(
        LoginAction.loginSuccess({
          isLoginSuccess: true,
        }),
      );
    } else {
      yield put(
        LoginAction.conversationSet({
          isLoginErr: true,
        }),
      );
    }
  } catch (error) {
    yield put(LoginAction.loginFailure(error));
  }
}
