import { call, put, select } from "redux-saga/effects";
import { ApiConstant } from "const";
import { UserService } from "services";
import UserAction from "redux/user.redux";
import { toCamel } from "utils";

export function* getUserList() {
  try {
    let response = yield call(UserService.getListUserData);
    const userListData = response.data?.data;
    if (response.status === 200) {
      yield put(
        UserAction.userSet({
          data: toCamel(response.data.data),
        }),
      );
    } else {
      yield put(UserAction.userFailure(response.data));
    }
  } catch (error) {
    yield put(UserAction.userFailure(error));
  }
}

export function* createUser(action) {
  try {
    const { data } = action;

    let response = yield call(UserService.postUser, data);
    if (response.status === 200) {
      yield put(UserAction.userSuccess({ isCreateSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(UserAction.userFailure(responseData));
    }
  } catch (error) {
    yield put(UserAction.userFailure(error));
  }
}

export function* deleteUser(action) {
  try {
    const { data } = action;
    let response = yield call(UserService.deleteUser, data);
    if (response.status === 200) {
      yield put(UserAction.userSuccess({ isDeleteSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(UserAction.userFailure(responseData));
    }
  } catch (error) {
    yield put(UserAction.userFailure(error));
  }
}

export function* updateUser(action) {
  try {
    const { data } = action;
    let response = yield call(UserService.updateUser, data);
    if (response.status === 200) {
      yield put(UserAction.userSuccess({ isUpdateSuccess: true }));
    } else {
      let responseData = response.data?.error;
      yield put(UserAction.userFailure(responseData));
    }
  } catch (error) {
    yield put(UserAction.userFailure(error));
  }
}
