import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableDetails from "./TableDetails";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import UpdateDetailss from "./updateDetails/UpdateDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabDetails = ({ onClose, valueTask }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className={classes.wrapTabs}>
        <Tabs
          classes={{
            flexContainer: classes.flexContainer,
            indicator: classes.indicator,
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab classes={{ selected: classes.selected }} label="Thông tin chung" {...a11yProps(0)} />
          <Tab classes={{ selected: classes.selected }} label="Trạng thái" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel classes={{ root: classes.tab }} value={value} index={0}>
        <TableDetails valueTask = {valueTask}/>
      </TabPanel>
      <TabPanel classes={{ root: classes.tab }} value={value} index={1}>
        <UpdateDetailss onClose={onClose} />
      </TabPanel>
    </>
  );
};

export default TabDetails;

const useStyles = makeStyles(theme => ({
  flexContainer: {
    justifyContent: "center",
  },
  indicator: {
    display: "none",
  },
  selected: {
    color: "#2E2E2E !important",
    background: "#F5F5F5 !important",
    borderRadius: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  root: {
    minHeight: "30px !important",
  },
  tab: {
    width: "100%",
  },
  wrapTabs: {
    marginTop: 10,
  },
}));
