import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Typography, IconButton } from "@mui/material";
import { DynamicForm, Science, ManageAccounts, Category, Bolt, Person, Logout } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import RemindAction from "redux/remind.redux";
import { handlingLogout } from "utils";
const roleId = Cookies.get("role-id");

const listItems =
  +roleId === 1 || +roleId === 2
    ? [
        {
          listIcon: <DynamicForm />,
          listText: "Xiết tiếp xúc",
          route: "/contact-tightening",
          id: 0,
        },
        {
          listIcon: <Science />,
          listText: "Thí nghiệm",
          route: "/experiment",
          id: 1,
        },
        {
          listIcon: <Category />,
          listText: "Xuất báo cáo",
          route: "/export-report",
          id: 2,
        },
        {
          listIcon: <ManageAccounts />,
          listText: "Quản lý nhân sự",
          route: "/management-hr",
          id: 3,
        },
        {
          listIcon: <Bolt />,
          listText: "Quản lý trạm",
          route: "/management-substation",
          id: 4,
        },
        {
          listIcon: <Person />,
          listText: "Cá nhân",
          route: "/profile",
          id: 5,
        },
      ]
    : [
        {
          listIcon: <DynamicForm />,
          listText: "Xiết tiếp xúc",
          route: "/contact-tightening",
          id: 0,
        },
        {
          listIcon: <Science />,
          listText: "Thí nghiệm",
          route: "/experiment",
          id: 1,
        },
        {
          listIcon: <Category />,
          listText: "Xuất báo cáo",
          route: "/export-report",
          id: 2,
        },
        {
          listIcon: <Person />,
          listText: "Cá nhân",
          route: "/profile",
          id: 5,
        },
      ];

export default function SideBarContent() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataProfile = useSelector(state => state.remindRedux.dataSet);

  useEffect(() => {
    dispatch(
      RemindAction.apiProfile({
        id: Cookies.get("accountId"),
      }),
    );
  }, []);

  const handleLogout = () => {
    handlingLogout();
    window.location.reload();
  };

  return (
    <Box className={classes.menuSliderContainer} component="div">
      <Box className={classes.userInf} classes={classes.userInf}>
        <Box>
          <Typography className={classes.listText} variant="h6">
            {dataProfile?.fullname}
          </Typography>
          <Typography className={classes.listText} variant="h7">
            {dataProfile?.role?.name}
          </Typography>
        </Box>
        <IconButton className={classes.listItem} onClick={handleLogout}>
          <Logout />
        </IconButton>
      </Box>

      <List>
        {listItems.map((listItem, index) => (
          <ListItem className={classes.active} button key={index} component={Link} to={listItem.route}>
            <ListItemIcon
              className={classes.listItem}
              classes={{
                root: classes.listIcon,
              }}
            >
              {listItem.listIcon}
            </ListItemIcon>
            <ListItemText className={classes.listText} primary={listItem.listText} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
const useStyles = makeStyles(theme => ({
  menuSliderContainer: {
    width: 220,
  },
  userInf: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "20px",
    padding: "20px",
    flexWrap: "wrap",
  },
  listItem: {
    fontSize: "15px",
    color: "#ffff",
  },
  listIcon: {
    minWidth: "40px",
  },
  active: {
    fontSize: "15px",
    color: "#ffff",
  },
  unActive: {
    fontSize: "15px",
    color: "#ffff",
  },
  listText: {
    color: "#ffff",
  },
}));
