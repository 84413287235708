import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../../theme/img/logo.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginAction from "../../redux/login.redux";

const LoginInput = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const isLoginErr = useSelector(state => state.loginRedux.isLoginErr);
  const [isRemberme, setIsRemberme] = useState(false);

  const handleShowPass = () => {
    setShowPassword(prev => !prev);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().email().required("Vui lòng điền username!"),
    password: Yup.string().required("Vui lòng điền mật khẩu!"),
  });

  const onChaneChecked = e => {
    setIsRemberme(e.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: value => {
      if (isRemberme) {
        localStorage.setItem("username", formik.values.username);
        localStorage.setItem("remember-phone", true);
        localStorage.setItem("password", formik.values.password);
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("remember-phone");
        localStorage.removeItem("password");
      }
      dispatch(LoginAction.checkLogin(value));
    },
  });

  useEffect(() => {
    let usernameLocalStorage = localStorage.getItem("username");
    let passwordLocalStorage = localStorage.getItem("password");

    if (Boolean(usernameLocalStorage) && Boolean(passwordLocalStorage)) {
      formik.setValues({
        username: usernameLocalStorage,
        password: passwordLocalStorage,
      });
      setIsRemberme(Boolean(localStorage.getItem("remember-phone")));
    }
  }, []);

  useEffect(() => {
    if (isLoginErr) {
      alert("Thông tin tài khoản hoặc mật khẩu không chính xác");
    }
    dispatch(LoginAction.resetRedux());
  }, [isLoginErr]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === "Enter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <>
      <Box className={classes.logoImage} my={1}></Box>
      <Typography variant="h3" align="center" my={1}>
        Quản lý nhắc việc
      </Typography>
      <form className={classes.formLogin}>
        <Typography my={1}>Email</Typography>
        <TextField
          className={classes.textField}
          fullWidth
          my={1}
          id="username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <Typography my={1}>Password</Typography>
        <TextField
          className={classes.textField}
          fullWidth
          my={1}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleShowPass} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Nhớ mật khẩu"
          my={1}
          onChange={onChaneChecked}
          checked={isRemberme}
        />
        <Button color="primary" variant="contained" fullWidth onClick={formik.handleSubmit} my={1}>
          Đăng nhập
        </Button>
      </form>
    </>
  );
};

export default LoginInput;

const useStyles = makeStyles(theme => ({
  logoImage: {
    backgroundImage: `url(${Logo})`,
    width: "100px",
    height: "100px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  formLogin: {
    formLogin: {
      width: "500px",
    },
  },
}));
