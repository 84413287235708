import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "sagas";
import * as RemindRedux from "./remind.redux";
import * as LoginRedux from "./login.redux";
import * as SubstationRedux from "./substation.redux";
import * as UserRedux from "./user.redux";
import * as FileRedux from "./file.redux";

/* ------------- Assemble The Reducers ------------- */
export const appReducer = combineReducers({
  remindRedux: RemindRedux.reducer,
  loginRedux: LoginRedux.reducer,
  substationRedux: SubstationRedux.reducer,
  userRedux: UserRedux.reducer,
  fileRedux: FileRedux.reducer,
});

export const rootReducer = (state, action) => {
  // Action logout
  // if (action.type === KeyConstant.LOGOUT_REQUEST) {
  //   state = undefined;
  // }
  return appReducer(state, action);
};

/* ------------- Redux Configuration ------------- */

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();

// Create store
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// kick off root saga
sagaMiddleware.run(rootSaga);

export default store;
