import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  Table,
  TableHead,
  Link,
  Tooltip,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogImgDelete from "../../theme/img/Delete.png";
import { useDispatch, useSelector } from "react-redux";
import UserAction from "redux/user.redux";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import RemindRedux from "redux/remind.redux";
function createData(id, fullname, username, phone, role_name, role_number, group_name, group_id) {
  return { id, fullname, username, phone, role_name, role_number, group_name, group_id };
}

const rowsPerPage = 100;
const page = 0;

function ManagementHR() {
  const [openDeleteHR, setOpenDeleteHR] = useState(false);
  const [openUpdateHR, setOpenUpdateHR] = useState(false);
  const [openAddHR, setOpenAddHR] = useState(false);
  const [valueDelete, setValueDelete] = useState({});
  const [dataRow, setDataRow] = useState([]);
  const [valueShowTable, setValueShowTable] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(UserAction.getUserList());
  }, []);
  const data_response = useSelector(state => state.userRedux.data);

  useEffect(() => {
    if (data_response && data_response.length) {
      let result = [];
      data_response.map(item => {
        result.push(
          createData(
            item.id,
            item.fullname,
            item.username,
            item.phone,
            item.role?.name,
            item.role?.number,
            item.group?.name,
            item.group?.id,
          ),
        );
      });
      setDataRow(result);
    }
  }, [data_response]);

  const handleClickDelete = e => {
    setOpenDeleteHR(true);
  };

  const handleClickUpdate = e => {
    setOpenUpdateHR(true);
  };

  const handleItemClick = item => {
    let itemClick = {
      id: item.id,
      fullname: item.fullname,
      username: item.username,
      phone: item.phone,
      role_number: item.role_number,
      group_id: item.group_id,
    };
    setValueDelete(itemClick);
  };

  useEffect(() => {
    if (data_response && data_response.length) {
      let result = [];
      data_response.map(item => {
        result.push(
          createData(
            item.id,
            item.fullname,
            item.username,
            item.phone,
            item.role?.name,
            item.role?.number,
            item.group?.name,
            item.group?.id,
          ),
        );
      });
      setDataRow(result);
    }
  }, [data_response]);

  const handleClose = () => {
    setOpenAddHR(false);
    setOpenDeleteHR(false);
    setOpenUpdateHR(false);
  };

  useEffect(() => {
    if (valueShowTable && valueShowTable.length) {
      let result = [];
      valueShowTable.map(item => {
        result.push(
          createData(
            item.id,
            item.fullname,
            item.username,
            item.phone,
            item.role?.name,
            item.role?.number,
            item.group?.name,
            item.group?.id,
          ),
        );
      });
      setValueShowTable([]);
      setDataRow(result);
    }
  }, [valueShowTable]);

  return (
    <Container maxWidth={false}>
      <Typography variant="h5">Quản lý nhân sự</Typography>

      <TableContainer
        component={Paper}
        classes={{
          root: classes.tableContainer,
        }}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Tên nhân sự</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">SĐT</TableCell>
              <TableCell align="center">Chức vụ</TableCell>
              <TableCell align="center">Phạm vi</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? dataRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : dataRow).map(
              (row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => handleItemClick(row)}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    <Link onClick={handleClickUpdate}>{row.fullname}</Link>
                  </TableCell>
                  <TableCell align="center"> {row.username} </TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.role_name}</TableCell>
                  <TableCell align="center">{row.group_name}</TableCell>
                  <TableCell>
                    <Tooltip title="Reset Password">
                      <IconButton onClick={handleClickDelete}>
                        <RestartAltIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteHR open={openDeleteHR} handleClose={handleClose} value={valueDelete} />
      <UpdateHR open={openUpdateHR} handleClose={handleClose} value={valueDelete} />
      <AddHR open={openAddHR} handleClose={handleClose} />
    </Container>
  );
}

export default ManagementHR;

const DeleteHR = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isDeleteSuccess = useSelector(state => state.userRedux.isDeleteSuccess);

  const handleDelete = () => {
    //TODO: API RESET PASSWORD
    dispatch(
      RemindRedux.resetPassword({
        userId: props.value.id,
      }),
    );
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(
        UserAction.userSet({
          isDeleteSuccess: false,
        }),
      );
      dispatch(UserAction.getUserList());
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
        classes={{
          paper: classes.dialog1,
        }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogImg}></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" variant="h6">
            <Typography style={{ fontWeight: "Bold" }}>
              Bạn có muốn reset password " {props.value.fullname}" không?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
              handleDelete();
            }}
            color="primary"
            variant="contained"
            style={{ width: "200px" }}
          >
            Có
          </Button>
          <Button onClick={props.handleClose} color="secondary" variant="contained" style={{ width: "200px" }}>
            Không
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdateHR = props => {
  const classes = useStyles();
  const isUpdateSuccess = useSelector(state => state.userRedux.isUpdateSuccess);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    fullname: Yup.string(),
    username: Yup.string().email().required("Vui lòng điền email!"),
    phone: Yup.string(),
    role_number: Yup.number(),
    group_id: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
  });

  const onSubmit = () => {
    let bodyValue = {
      id: props.value.id,
      phone: formik.values.phone,
      fullname: formik.values.fullname,
      role_number: formik.values.role_number,
      group_id: formik.values.group_id,
    };
    dispatch(UserAction.updateUser(bodyValue));
  };

  useEffect(() => {
    if (props.value) {
      formik.setValues(props.value);
    }
  }, [props]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        UserAction.userSet({
          isUpdateSuccess: false,
        }),
      );
      dispatch(UserAction.getUserList());
      formik.resetForm();
      props.handleClose();
    }
  }, [isUpdateSuccess]);

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} maxWidth="800px">
        <DialogTitle>Sửa nhân sự</DialogTitle>
        <DialogContent>
          <form>
            <Typography my={1}>Tên nhân sự</Typography>
            <TextField
              className={classes.textField}
              fullWidth
              my={1}
              id="fullname"
              name="fullname"
              value={formik.values.fullname}
              onChange={formik.handleChange}
              error={formik.touched.fullname && Boolean(formik.errors.fullname)}
              helperText={formik.touched.fullname && formik.errors.fullname}
            />
            <Box>
              <FormControl sx={{ minWidth: 120 }}>
                <Typography my={1}>Chức vụ</Typography>
                <Select
                  defaultValue={"Lựa chọn"}
                  onChange={formik.handleChange}
                  displayEmpty
                  id="role_number"
                  name="role_number"
                  value={formik.values.role_number}
                >
                  <MenuItem value={2}>Đội trưởng</MenuItem>
                  <MenuItem value={3}>Đội phó</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 120 }}>
                <Typography my={1}>Tổ quản lý</Typography>
                <Select
                  defaultValue={"Lựa chọn"}
                  onChange={formik.handleChange}
                  displayEmpty
                  id="group_id"
                  name="group_id"
                  value={formik.values.group_id}
                >
                  <MenuItem value={1}>Đội 1</MenuItem>
                  <MenuItem value={2}>Đội 2</MenuItem>
                  <MenuItem value={3}>Đội 3</MenuItem>
                  <MenuItem value={4}>Đội 4</MenuItem>
                  <MenuItem value={5}>Đội 5</MenuItem>
                  <MenuItem value={9}>Đội F9</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Typography my={1}>Email</Typography>
            <TextField
              className={classes.textField}
              sx={{ minWidth: 700 }}
              my={1}
              id="username"
              name="username"
              placeholder="Nhập địa chỉ email"
              value={formik.values.username}
              onChange={formik.handleChange}
              disabled
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              type={"text"}
            />
            <Typography my={1}>Số điện thoại</Typography>
            <TextField
              className={classes.textField}
              fullWidth
              my={1}
              id="phone"
              name="phone"
              placeholder="Nhập số điện thoại"
              value={formik.values.phone}
              onChange={formik.handleChange}
              type={"text"}
            />
          </form>
        </DialogContent>
        <DialogActions
          className="diaAction"
          classes={{
            root: classes.diaAction,
          }}
        >
          <Button color="primary" variant="contained" onClick={onSubmit} my={1}>
            Lưu
          </Button>
          <Button onClick={props.handleClose} color="primary" variant="contained">
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddHR = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const isCreateSuccess = useSelector(state => state.userRedux.isCreateSuccess);

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required("Vui lòng điền họ tên!"),
    username: Yup.string().email().required("Vui lòng điền email!"),
    phone: Yup.string(),
    role_number: Yup.number(),
    group_id: Yup.number(),
    password: Yup.string().required("Vui lòng điền mật khẩu!"),
    re_password: Yup.string().oneOf([Yup.ref("password"), null], "Mật khẩu không khớp"),
  });

  const formik = useFormik({
    initialValues: {
      fullname: "",
      username: "",
      phone: "",
      role_number: 1,
      group_id: 1,
      password: "",
      re_password: "",
    },
    validationSchema,
    onSubmit: value => {
      let bodyValue = {
        username: value.username,
        password: value.password,
        phone: value.phone,
        fullname: value.fullname,
        role_number: value.role_number,
        group_id: value.group_id,
      };
      dispatch(UserAction.createUser(bodyValue));
    },
  });

  const handleShowPass = () => {
    setShowPassword(prev => !prev);
  };
  const handleShowRePass = () => {
    setShowRePassword(prev => !prev);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(
        UserAction.userSet({
          isCreateSuccess: false,
        }),
      );
      dispatch(UserAction.getUserList());
      formik.resetForm();
      props.handleClose();
    }
  }, [isCreateSuccess]);

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} maxWidth="800px">
        <DialogTitle>Thêm nhân sự</DialogTitle>
        <DialogContent>
          <form>
            <Typography my={1}>Tên nhân sự</Typography>
            <TextField
              className={classes.textField}
              fullWidth
              my={1}
              id="fullname"
              name="fullname"
              placeholder="Nhập tên nhân sự"
              value={formik.values.fullname}
              onChange={formik.handleChange}
              error={formik.touched.fullname && Boolean(formik.errors.fullname)}
              helperText={formik.touched.fullname && formik.errors.fullname}
            />
            <Box>
              <FormControl sx={{ minWidth: 120 }}>
                <Typography my={1}>Chức vụ</Typography>
                <Select
                  defaultValue={"Lựa chọn"}
                  onChange={formik.handleChange}
                  displayEmpty
                  id="role_number"
                  name="role_number"
                  value={formik.values.role_number}
                >
                  <MenuItem value={1}>Quản lý</MenuItem>
                  <MenuItem value={2}>Đội trưởng</MenuItem>
                  <MenuItem value={3}>Đội phó</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 120 }}>
                <Typography my={1}>Tổ quản lý</Typography>
                <Select
                  defaultValue={"Lựa chọn"}
                  onChange={formik.handleChange}
                  displayEmpty
                  id="group_id"
                  name="group_id"
                  value={formik.values.group_id}
                >
                  <MenuItem value={1}>Đội 1</MenuItem>
                  <MenuItem value={2}>Đội 2</MenuItem>
                  <MenuItem value={3}>Đội 3</MenuItem>
                  <MenuItem value={4}>Đội 4</MenuItem>
                  <MenuItem value={5}>Đội 5</MenuItem>
                  <MenuItem value={9}>Đội F9</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Typography my={1}>Email</Typography>
            <TextField
              className={classes.textField}
              sx={{ minWidth: 700 }}
              my={1}
              id="username"
              name="username"
              placeholder="Nhập địa chỉ email"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              type={"text"}
            />
            <Typography my={1}>Số điện thoại</Typography>
            <TextField
              className={classes.textField}
              fullWidth
              my={1}
              id="phone"
              name="phone"
              placeholder="Nhập số điện thoại"
              value={formik.values.phone}
              onChange={formik.handleChange}
              type={"text"}
            />
            <Typography my={1}>Tạo mật khẩu</Typography>
            <TextField
              className={classes.textField}
              fullWidth
              my={1}
              id="password"
              name="password"
              placeholder="Nhập mật khẩu"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleShowPass} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography my={1}>Nhập lại mật khẩu</Typography>
            <TextField
              className={classes.textField}
              fullWidth
              my={1}
              id="re_password"
              name="re_password"
              placeholder="Nhập lại mật khẩu"
              value={formik.values.re_password}
              onChange={formik.handleChange}
              error={formik.touched.re_password && Boolean(formik.errors.re_password)}
              helperText={formik.touched.re_password && formik.errors.re_password}
              type={showRePassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleShowRePass} edge="end">
                      {showRePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </DialogContent>
        <DialogActions
          className="diaAction"
          classes={{
            root: classes.diaAction,
          }}
        >
          <Button color="primary" variant="contained" onClick={formik.handleSubmit} my={1}>
            Lưu
          </Button>
          <Button onClick={props.handleClose} color="primary" variant="contained">
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  // container: {
  //   display: "flex",
  //   alignItems: "center",
  //   paddingLeft: "0px",
  //   "@media (min-width: 600px)": {
  //     paddingLeft: "0px",
  //     paddingRight: "0px"
  //   },
  // },

  tableContainer: {
    marginTop: "20px",
  },
  addSubButton: {
    marginRight: "10px",
  },

  dialogImg: {
    backgroundImage: `url(${DialogImgDelete})`,
    backgroundRepeat: "no-repeat",
    height: "80px",
    width: "80px",
    alignItems: "center",
  },
  dialog1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 70px",
  },
  diaAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
