import { ApiConstant } from "../const";
import { createApiDownloadExcel, createApiRegistration, createApiWithToken } from "../api";
import StringFormat from "string-format";
import { da } from "date-fns/locale";

// export const getRemindListData = data => createApiRegistration().get(ApiConstant.GET_REMIND_LIST_API, data);
export const apiProfile = data => createApiRegistration().get(StringFormat(ApiConstant.API_PROFILE, data));

export const apiChangePassword = data => createApiRegistration().put(ApiConstant.API_CHANGE_PASSWORD, data);

export const getGantChatApi = data => createApiRegistration().post(ApiConstant.GANTT_CHAT, data);

export const updateIssue = data => createApiWithToken().put(StringFormat(ApiConstant.REMINDER_WITH_ID, data.id), data);

export const apiPopupGanttChart = data => createApiRegistration().get(ApiConstant.API_GANTT_CHART_POPUP, data);

export const editRemind = data =>
  createApiRegistration().put(StringFormat(ApiConstant.REMINDER_WITH_ID, data.issue.reminder_id), data);

export const deleteRemind = data => createApiRegistration().delete(ApiConstant.API_DELETE, data);

export const searchRemind = data => createApiRegistration().get(ApiConstant.API_SEARCH_REMINDER, data);

export const addRemind = data => createApiRegistration().post(ApiConstant.API_ADD_REMINDER, data);

export const remindDetails = data => createApiRegistration().get(ApiConstant.DETAILS_REMIND, data);

export const exportFile = data => createApiDownloadExcel().post(ApiConstant.GANTT_CHAT, data);

export const resetPassword = data =>
  createApiRegistration().post(StringFormat(ApiConstant.RESET_PASSWORD, data.userId), data);
