import * as React from "react";
import Dialog from "@mui/material/Dialog";
import imagePopup from "theme/img/IconConfirm.png";
import { Box } from "@mui/system";
import { Button, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import RemindAction from "redux/remind.redux";

const PopupDelete = ({ onClose, open, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickDelete = () => {
    const listId = data?.issues?.map(item => item.reminder_id);
    dispatch(
      RemindAction.deleteRemind({
        ids: listId,
      }),
    );
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <Box className={classes.poupDelete}>
          <CardMedia sx={{ width: "100px", margin: "auto", paddingTop: 2 }} component="img" src={imagePopup} />
          <Typography className={classes.titleDelete}>Bạn có muốn xóa trạm?</Typography>
          <Box className={classes.buttonDelete}>
            <Button onClick={onClickDelete} className={classes.marginButton} variant="contained">
              Có, tôi muốn xóa
            </Button>
            <Button onClick={onClose} variant="text">
              Không, giữ lại
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PopupDelete;
const useStyles = makeStyles(theme => ({
  titleDelete: {
    color: "#2E2E2E",
    display: "flex",
    justifyContent: "center",
    padding: "20px 0 40px 0",
  },
  poupDelete: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 30px",
    },
  },
  buttonDelete: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 35,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  marginButton: {
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
}));
