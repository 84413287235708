import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core";
import DetailsEachOne from "../DetailsEachOne";
import {useSelector} from "react-redux";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UpdateDetailss = ({onClose}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [tightening, setTightening] = useState();
  const [periodically, setPeriodically] = useState();
  const [repairMajor, setRepairMajor] = useState();
  const [construction, setContruction] = useState();
  const [experiment, setExperiment] = useState();
  const [boxAndPicture, setBoxAndPicture] = useState();
  const [meterBox, setMeterBox] = useState();
  const dataPopup = useSelector(state => state.remindRedux.gantChat);
  const selectedSubCode = useSelector(state => state.remindRedux.selectedSubCode);
  const selectedCode = useSelector(state => state.remindRedux.selectedCode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    dataPopup?.substation?.issues?.map(item => {
      if (item?.reminder_details !== null && item?.reminder_details.length > 0) {
        if (selectedSubCode !== undefined) {
          if (selectedSubCode !== 4) {
            if (item.code === 3) setTightening(item);
            if (item.code === 1) setPeriodically(item);
            if (item.code === 2) setRepairMajor(item);
            if (item.code === 5) setContruction(item);
            if (item.code === 6) setBoxAndPicture(item);
            if (item.code === 7) setMeterBox(item);
          } else {
            if (item.code === 4) setExperiment(item);
          }
          setValue(selectedSubCode - 1)
        } else {
          if (selectedCode === 8) {
            if (item.code === 3) setTightening(item);
            if (item.code === 1) setPeriodically(item);
            if (item.code === 2) setRepairMajor(item);
            if (item.code === 5) setContruction(item);
            if (item.code === 6) setBoxAndPicture(item);
            if (item.code === 7) setMeterBox(item);
            setValue(5);
          } else {
            if (item.code === 4) setExperiment(item);
            setValue(3);
          }
        }
      }
    });

  }, [selectedSubCode]);

  return (
    <>
      <Tabs
        classes={{
          flexContainer: classes.flexContainer,
          indicator: classes.indicator,
        }}
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tightening ? <Tab classes={{selected: classes.selected}} label="Xiết tiếp xúc" {...a11yProps(0)} /> : <></>}
        {periodically ? <Tab classes={{selected: classes.selected}} label="Thay định kỳ" {...a11yProps(1)} /> : <></>}
        {repairMajor ? <Tab classes={{selected: classes.selected}} label="Sửa chữa lớn" {...a11yProps(2)} /> : <></>}
        {experiment ? <Tab classes={{selected: classes.selected}} label="Thí Nghiệm" {...a11yProps(3)} /> : <></>}
        {construction ? (
          <Tab classes={{selected: classes.selected}} label="Đầu tư xây dựng" {...a11yProps(4)} />
        ) : (
          <></>
        )}
        {boxAndPicture ? <Tab classes={{selected: classes.selected}}
                              label="Hộp phân dây & Ảnh nhiệt ghíp" {...a11yProps(5)} /> : <></>}
        {meterBox ? <Tab classes={{selected: classes.selected}} label="XTX hòm công tơ" {...a11yProps(6)} /> : <></>}
      </Tabs>

      {tightening && (
        <TabPanel value={value} index={0}>
          <DetailsEachOne currentdata={dataPopup} onClose={onClose} data={tightening}/>
        </TabPanel>
      )}
      {periodically && (
        <TabPanel value={value} index={1}>
          <DetailsEachOne currentdata={dataPopup} onClose={onClose} data={periodically}/>
        </TabPanel>
      )}
      {repairMajor && (
        <TabPanel value={value} index={2}>
          <DetailsEachOne currentdata={dataPopup} onClose={onClose} data={repairMajor}/>
        </TabPanel>
      )}
      {experiment && (
        <TabPanel value={value} index={3}>
          <DetailsEachOne currentdata={dataPopup} onClose={onClose} data={experiment}/>
        </TabPanel>
      )}
      {construction && (
        <TabPanel value={value} index={4}>
          <DetailsEachOne currentdata={dataPopup} onClose={onClose} data={construction}/>
        </TabPanel>
      )}
      {boxAndPicture && (
        <TabPanel value={value} index={5}>
          <DetailsEachOne currentdata={dataPopup} onClose={onClose} data={boxAndPicture}/>
        </TabPanel>
      )}
      {meterBox && (
        <TabPanel value={value} index={6}>
          <DetailsEachOne currentdata={dataPopup} onClose={onClose} data={meterBox}/>
        </TabPanel>
      )}
    </>
  );
};

export default UpdateDetailss;

const useStyles = makeStyles(theme => ({
  indicator: {
    display: "none",
  },
  selected: {
    color: "#2E2E2E !important",
    background: "#F5F5F5 !important",
    borderRadius: 20,
  },

  flexContainer: {
    minWidth: 900,
    marginBottom: 10,
  },
}));
