import * as React from "react";
import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  Table,
  TableHead,
  Checkbox,
  Pagination,
} from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Select, MenuItem } from "@mui/material";
import { Search, AddCircleSharp, RemoveCircle } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SubstationAction from "redux/substation.redux";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant } from "const";
import { getYear } from "date-fns";
import { isArrayNotEquals, textNormalize } from "utils";

const rowsPerPage = 20;

const ManagementSub = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const substationListRedux = useSelector(state => state.substationRedux.substationList);
  const isUpdateSuccess = useSelector(state => state.substationRedux.isUpdateSuccess);
  const isDeleteSuccess = useSelector(state => state.substationRedux.isDeleteSuccess);

  const [disabledBtn, setDisabledBtn] = useState(true);
  const [substationList, setSubstationList] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteList, setDeleteList] = useState([]);
  const [page, setPage] = useState(1);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [valueUpdate, setValueUpdate] = useState({});

  const handleClickAdd = () => {
    setOpenAdd(true);
  };

  const handleClickUpdate = row => {
    setValueUpdate(row);
    setOpenUpdate(true);
  };

  const handleClose = () => {
    setOpenAdd(false);
    setOpenUpdate(false);
  };

  const onChangeSearch = event => {
    let value = event.target.value;
    setSearch(value);
  };

  const onSearch = () => {
    let tmpResult = [...substationListRedux];
    tmpResult = getFilterArray(substationListRedux, search);
    setSubstationList(tmpResult);
  };

  const onSelectStation = data => {
    setValueUpdate(data);
    let tmp = [...deleteList];
    if (tmp.includes(data.code)) {
      tmp = tmp.filter(item => item !== data.code);
    } else {
      tmp.push(data.code);
    }
    setDeleteList(tmp);
  };

  const onDeleteStation = () => {
    dispatch(
      SubstationAction.deleteSubstation({
        codes: deleteList,
      }),
    );
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(SubstationAction.getListSubstation());
  }, []);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(
        SubstationAction.substationSet({
          isDeleteSuccess: false,
        }),
      );
      dispatch(SubstationAction.getListSubstation());
    }
  });

  useEffect(() => {
    if (isArrayNotEquals(substationListRedux, substationList) && substationListRedux.length > 0) {
      setSubstationList(substationListRedux);
    }
  }, [substationListRedux]);

  useEffect(() => {
    setDisabledBtn(deleteList.length === 0);
  }, [deleteList]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setValueUpdate({});
    }
  }, [isUpdateSuccess]);

  return (
    <Container maxWidth={false}>
      <Typography variant="h5">Quản lý trạm</Typography>
      <Box justifyContent="space-between" display="flex">
        <Box>
          <Button
            color="primary"
            classes={{
              root: classes.addSubButton,
            }}
            variant="contained"
            type="submit"
            onClick={handleClickAdd}
            endIcon={<AddCircleSharp />}
          >
            Thêm trạm
          </Button>
          <Button
            color="secondary"
            variant="contained"
            endIcon={<RemoveCircle />}
            disabled={disabledBtn}
            classes={{
              root: classes.addSubButton,
            }}
            onClick={onDeleteStation}
          >
            Xóa trạm
          </Button>
        </Box>
      </Box>
      <TextField
        fullWidth
        my={1}
        type="text"
        onChange={onChangeSearch}
        onKeyDown={event => {
          if (event.key === "Enter" || event.keyCode === 13) {
            onSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" edge="end" onClick={onSearch}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {deleteList.length > 0 ? <Typography> Đã chọn : {deleteList.length} trạm</Typography> : <></>}
      <TableContainer
        component={Paper}
        classes={{
          root: classes.tableContainer,
        }}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Đội</TableCell>
              <TableCell align="center">Tên trạm</TableCell>
              <TableCell align="center">Mã trạm</TableCell>
              <TableCell align="center">Hãng sản xuất</TableCell>
              <TableCell align="center">Năm vận hành</TableCell>
              <TableCell align="center">Tài sản</TableCell>
              <TableCell align="center">MBA</TableCell>
              <TableCell align="center">Tiếp địa</TableCell>
              <TableCell align="center">Chống sét</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {substationList.length > 0 &&
              (rowsPerPage > 0
                ? substationList.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                : substationList
              ).map((row, index) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      onSelectStation(row);
                    }}
                  >
                    <TableCell>
                      <Checkbox checked={Boolean(deleteList.includes(row.code))} />
                    </TableCell>
                    <TableCell align="center">{index + 1 + (page - 1) * rowsPerPage}</TableCell>
                    <TableCell align="center">{row.group?.name}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">
                      <Button
                        type="text"
                        onClick={event => {
                          event.stopPropagation();
                          event.preventDefault();
                          handleClickUpdate(row);
                        }}
                      >
                        {row.code}
                      </Button>
                    </TableCell>
                    <TableCell align="center">{row.manufacturer}</TableCell>
                    <TableCell align="center">{row.year_of_operation}</TableCell>
                    <TableCell align="center">{row.owner}</TableCell>
                    <TableCell align="center">{row.transformers}</TableCell>
                    <TableCell align="center">{row.earthing}</TableCell>
                    <TableCell align="center">{row.lightning_conductor}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.floor(substationList.length / rowsPerPage) + 1}
        variant="outlined"
        color="primary"
        sx={{ paddingTop: 5 }}
        page={page}
        onChange={handleChangePage}
        hidePrevButton
        hideNextButton
      />
      <AddSubstation open={openAdd} handleClose={handleClose} />
      <UpdateSubstation open={openUpdate} handleClose={handleClose} value={valueUpdate} />
    </Container>
  );
};

const AddSubstation = ({ open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isCreateSuccess = useSelector(state => state.substationRedux.isCreateSuccess);

  const [startYear, setStartYear] = useState(new Date());

  const validationSchema = Yup.object().shape({
    group_id: Yup.string(),
    code: Yup.string(),
    name: Yup.string(),
    manufacturer: Yup.string(),
    year_of_operation: Yup.number(),
    owner: Yup.string(),
    transformers: Yup.number(),
    earthing: Yup.number(),
    lightning_conductor: Yup.number(),
    custommer: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      group_id: 0,
      code: "",
      name: "",
      manufacturer: "",
      owner: "",
      transformers:0,
      earthing: 0,
      lightning_conductor: 0,
      year_of_operation: 2022,
      customers: 0,
    },
    validationSchema,

    onSubmit: value => {
      dispatch(
        SubstationAction.createSubstation({
          ...value,
          year_of_operation: getYear(startYear),
        }),
      );
    },
  });

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(
        SubstationAction.substationSet({
          isCreateSuccess: false,
        }),
      );
      formik.resetForm();
      dispatch(SubstationAction.getListSubstation());
      handleClose();
    }
  }, [isCreateSuccess]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="800px">
      <DialogTitle>Thêm trạm</DialogTitle>
      <DialogContent>
        <form>
          <FormControl sx={{ minWidth: 120 }}>
            <Typography my={1}>Tổ quản lý</Typography>
            <Select
              defaultValue={"Lựa chọn"}
              onChange={formik.handleChange}
              displayEmpty
              id="group_id"
              name="group_id"
              value={formik.values.group_id}
            >
              <MenuItem disabled value={0}>
                Lựa chọn
              </MenuItem>
              {AppConstant.TEAM_LIST.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography my={1}>Mã trạm</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            my={1}
            id="code"
            name="code"
            placeholder="Nhập mã trạm"
            value={formik.values.code}
            onChange={formik.handleChange}
          />
          <Typography my={1}>Tên trạm</Typography>
          <TextField
            className={classes.textField}
            sx={{ minWidth: 700 }}
            my={1}
            id="name"
            name="name"
            placeholder="Nhập tên trạm"
            value={formik.values.name}
            onChange={formik.handleChange}
            type={"text"}
          />
          <Typography my={1}>Hãng sản xuất</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            my={1}
            id="manufacturer"
            name="manufacturer"
            placeholder="Nhập hãng sản xuất"
            value={formik.values.manufacturer}
            onChange={formik.handleChange}
            type={"text"}
          />
          <Typography my={1}>Năm vận hành</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year"]}
              name="year_of_operation"
              value={
                formik.values.year_of_operation ? new Date().setFullYear(formik.values.year_of_operation) : new Date()
              }
              onChange={newValue => setStartYear(newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  helperText={null}
                  classes={{
                    root: classes.filterInfo,
                  }}
                  className="filterInfo"
                />
              )}
            />
          </LocalizationProvider>
          <Typography my={1}>Tài sản</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            my={1}
            id="owner"
            name="owner"
            placeholder="Nhập tài sản"
            value={formik.values.owner}
            onChange={formik.handleChange}
            type={"text"}
          />
          <Box>
            <FormControl sx={{ minWidth: 120 }}>
              <Typography my={1}>MBA</Typography>
              <TextField
                inputProps={{
                  min: 0,
                  type: "number",
                  max: 20,
                }}
                name="transformers"
                value={formik.values.transformers}
                onChange={event => {
                  let value = event.target.value;
                  if (value > 20) event.target.value = 20;
                  if (value < 0) event.target.value = Math.abs(value);
                  formik.handleChange(event);
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ minWidth: 120 }}>
              <Typography my={1}>Tiếp địa</Typography>
              <TextField
                inputProps={{
                  min: 0,
                  type: "number",
                  max: 20,
                }}
                value={formik.values.earthing}
                name="earthing"
                onChange={event => {
                  let value = event.target.value;
                  if (value > 20) event.target.value = 20;
                  if (value < 0) event.target.value = Math.abs(value);
                  formik.handleChange(event);
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ minWidth: 120 }}>
              <Typography my={1}>Chống sét</Typography>
              <TextField
                inputProps={{
                  min: 0,
                  type: "number",
                  max: 20,
                }}
                name="lightning_conductor"
                value={formik.values.lightning_conductor}
                onKeyDown={evt => {
                  if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
                    evt.preventDefault();
                  }
                }}
                onChange={event => {
                  let value = event.target.value;
                  if (value > 20) event.target.value = 20;
                  if (value < 0) event.target.value = Math.abs(value);
                  formik.handleChange(event);
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ minWidth: 120 }}>
              <Typography my={1}>Khách hàng</Typography>
              <TextField
                inputProps={{
                  min: 0,
                  type: "number",
                  max: 200,
                }}
                name="customers"
                value={formik.values.customers}
                onKeyDown={evt => {
                  if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
                    evt.preventDefault();
                  }
                }}
                onChange={event => {
                  let value = event.target.value;
                  if (value > 200) event.target.value = 200;
                  if (value < 0) event.target.value = Math.abs(value);
                  formik.handleChange(event);
                }}
              />
            </FormControl>
          </Box>
        </form>
      </DialogContent>
      <DialogActions
        className="diaAction"
        classes={{
          root: classes.diaAction,
        }}
      >
        <Button color="primary" variant="contained" onClick={formik.handleSubmit} my={1}>
          Lưu
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          Hủy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateSubstation = ({ value, open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUpdateSuccess = useSelector(state => state.substationRedux.isUpdateSuccess);

  const validationSchema = Yup.object().shape({
    group_id: Yup.string(),
    code: Yup.string(),
    owner: Yup.string(),
    name: Yup.string(),
    manufacturer: Yup.string(),
    year_of_operation: Yup.string(),
    transformers: Yup.string(),
    earthing: Yup.string(),
    lightning_conductor: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
  });

  useEffect(() => {
    if (value) {
      formik.setValues({ ...value, group: value?.group?.id });
    }
  }, [value, open]);

  const onSubmit = () => {
    let valueRequest = {
      group_id: formik.values.group,
      code: formik.values.code,
      owner: formik.values.owner,
      name: formik.values.name,
      manufacturer: formik.values.manufacturer,
      year_of_operation: formik.values.year_of_operation,
      transformers: formik.values.transformers,
      earthing: formik.values.earthing,
      lightning_conductor: formik.values.lightning_conductor,
    };

    dispatch(SubstationAction.updateSubstation(valueRequest));
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        SubstationAction.substationSet({
          isUpdateSuccess: false,
        }),
      );
      dispatch(SubstationAction.getListSubstation());
      formik.resetForm();
      handleClose();
    }
  }, [isUpdateSuccess]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="800px">
      <DialogTitle>Sửa trạm</DialogTitle>
      <DialogContent>
        <form>
          <FormControl sx={{ minWidth: 120 }}>
            <Typography my={1}>Tổ quản lý</Typography>
            <Select
              defaultValue={"Lựa chọn"}
              onChange={formik.handleChange}
              displayEmpty
              id="group"
              name="group"
              value={formik.values.group}
            >
              <MenuItem disabled value={0}>
                Lựa chọn
              </MenuItem>
              {AppConstant.TEAM_LIST.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography my={1}>Mã trạm</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            my={1}
            id="code"
            name="code"
            placeholder="Nhập mã trạm"
            value={formik.values.code}
            onChange={formik.handleChange}
          />
          <Typography my={1}>Tên trạm</Typography>
          <TextField
            className={classes.textField}
            sx={{ minWidth: 700 }}
            my={1}
            id="name"
            name="name"
            placeholder="Nhập tên trạm"
            value={formik.values.name}
            onChange={formik.handleChange}
            type={"text"}
          />
          <Typography my={1}>Hãng sản xuất</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            my={1}
            id="manufacturer"
            name="manufacturer"
            placeholder="Nhập hãng sản xuất"
            value={formik.values.manufacturer}
            onChange={formik.handleChange}
            type={"text"}
          />
          <Typography my={1}>Năm vận hành</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year"]}
              name="year_of_operation"
              value={new Date().setFullYear(formik.values.year_of_operation)}
              onChange={newValue => {
                formik.setValues({
                  ...formik.values,
                  year_of_operation: getYear(newValue),
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  helperText={null}
                  classes={{
                    root: classes.filterInfo,
                  }}
                  className="filterInfo"
                />
              )}
            />
          </LocalizationProvider>
          <Typography my={1}>Tài sản</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            my={1}
            id="owner"
            name="owner"
            placeholder="Nhập tài sản"
            value={formik.values.owner}
            onChange={formik.handleChange}
            type={"text"}
          />
          <Box>
            <FormControl sx={{ minWidth: 120 }}>
              <Typography my={1}>MBA</Typography>
              <TextField
                inputProps={{
                  min: 0,
                  type: "number",
                  max: 20,
                }}
                name="transformers"
                value={formik.values.transformers}
                onChange={event => {
                  let value = event.target.value;
                  if (value > 20) event.target.value = 20;
                  if (value < 0) event.target.value = Math.abs(value);
                  formik.handleChange(event);
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ minWidth: 120 }}>
              <Typography my={1}>Tiếp địa</Typography>
              <TextField
                inputProps={{
                  min: 0,
                  type: "number",
                  max: 20,
                }}
                value={formik.values.earthing}
                name="earthing"
                onChange={event => {
                  let value = event.target.value;
                  if (value > 20) event.target.value = 20;
                  if (value < 0) event.target.value = Math.abs(value);
                  formik.handleChange(event);
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ minWidth: 120 }}>
              <Typography my={1}>Chống sét</Typography>
              <TextField
                inputProps={{
                  min: 0,
                  type: "number",
                  max: 20,
                }}
                name="lightning_conductor"
                value={formik.values.lightning_conductor}
                onKeyDown={evt => {
                  if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
                    evt.preventDefault();
                  }
                }}
                onChange={event => {
                  let value = event.target.value;
                  if (value > 20) event.target.value = 20;
                  if (value < 0) event.target.value = Math.abs(value);
                  formik.handleChange(event);
                }}
              />
            </FormControl>
          </Box>
        </form>
      </DialogContent>
      <DialogActions
        className="diaAction"
        classes={{
          root: classes.diaAction,
        }}
      >
        <Button color="primary" variant="contained" onClick={onSubmit} my={1}>
          Lưu
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          Hủy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagementSub;

export const getFilterArray = (memberArray, search) => {
  let filterItems = [];
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(item => textNormalize(item.name).includes(textNormalize(search)));
  }
  return filterItems;
};

const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: "20px",
    height: 700,
    overflow: "auto",
  },
  addSubButton: {
    marginRight: "10px",
    borderRadius: 10,
  },
  diaAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
