import React from "react";

const TaskContext = React.createContext({
  tasksCt: [],
  tasksExperiment:[],
  addTask : (id, type) => {},
  removeTask: (id, type) => {}
});

export default TaskContext;
