import { Box, styled } from "@mui/material";
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Sidebar from "./SideBar";
import { hasLogin } from "utils";
import { makeStyles } from "@material-ui/core";

export default function Layout() {
  const classes = useStyles();

  return hasLogin() ? (
    <Box className={classes.containerSidebar}>
      <Box style={{ width: 200 }}>
        <Sidebar />
      </Box>
      <Main  style={{
        maxWidth: "calc(100% - 280px)"
      }}>
        <Outlet />
      </Main>
    </Box>
  ) : (
    <Navigate to="/login" />
  );
}

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(5),
  minHeight: "100vh",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 0",
  },
}));

const useStyles = makeStyles(theme => ({
  containerSidebar: {
    display: "flex",
    overflow: "hidden",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));
