import React, { useEffect, useState } from "react";
import { Container, Typography, Box, Button, TextField, IconButton } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Add, Logout, Upload } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import Gantt from "../../Gantt";
import RemindAction from "../../redux/remind.redux";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch } from "react-redux";
import PopupFdf from "components/experiment/Reminderdetails/PostFileFdf/PopupFdf";
import AddReminder from "components/experiment/AddReminder";
import { format } from "date-fns";
import { handlingLogout } from "utils";
import Cookies from "js-cookie";
import { AppConstant } from "const";

const ContactTightening = () => {
  const dataGantChat = useSelector(state => state.remindRedux.dataGantt);
  const isSuccessDelete = useSelector(state => state.remindRedux.isSuccessDelete);
  const isFetch = useSelector(state => state.remindRedux.isSuccess);
  const uploadFileSuccess = useSelector(state => state.fileRedux.uploadFileSuccess);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = useState(new Date());
  const [status, setStatus] = useState(0);
  const [issue, setIssue] = useState(0);
  const [task, setTask] = useState(null);
  const [addReminder, setAddReminder] = useState(false);
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState(null);
  
  const handleChangeKeyword = event => {
    setKeyword(event.target.value);
  };

  const handleChange = event => {
    setStatus(event.target.value);
  };

  const handleChangeIssue = event => {
    setIssue(event.target.value);
  };

  const roleId = Cookies.get("role-id");

  useEffect(() => {
    dispatch(
      RemindAction.conversationSet({
        dateYearTightening: value.getFullYear(),
      })
    );
  }, [value]);
  useEffect(() => {
    dispatch(
      RemindAction.remindSet({
        selectedCode: 8,
      }),
    );
  });
  useEffect(() => {
    const handlerTimeout = setTimeout(() => {
      dispatch(
        RemindAction.getGantChatApi({
          estimate_year: value.getFullYear(),
          status: status,
          type: 1,
          keyword: keyword,
        }),
      );
    }, 500);

    return () => clearTimeout(handlerTimeout);

  }, [value, status, keyword]);

  useEffect(() => {
    if (isSuccessDelete || isFetch || uploadFileSuccess) {
      dispatch(
        RemindAction.getGantChatApi({
          estimate_year: value.getFullYear(),
          status: status,
          type: 1,
        }),
      );
      dispatch(RemindAction.resetRedux());
    }
  }, [isSuccessDelete, isFetch, uploadFileSuccess]);

  const formatData = data => {
    let result = [];
    data &&
      data?.forEach(item => {
        let checkSub = false;
        let superMinStatus = 3;

        if (item?.substation?.length > 0) {
          item?.substation?.forEach(item2 => {
            let minStatus = 3;

            let secondLayerId = `${item.id}-${item2.id}`;
            let checkIssue = false;

            if (item2?.issue?.length > 0) {
              item2?.issue?.forEach(item3 => {
                if (item3.id === issue || issue === 0) {
                  const date = new Date(item3?.start_time * 1000);
                  const month = date.getMonth() + 1 === 12 ? 1 : date.getMonth() + 2;
                  const year = date.getMonth() + 1 === 12 ? date.getFullYear() + 1 : date.getFullYear();
                  let thirdLayerId = `${secondLayerId}-${item3.id}`;
                  let estimate_date = new Date(item3.estimate_time * 1000);
                  result.push({
                    id: thirdLayerId,
                    text: item3.name,
                    start_date: format(item3.start_time * 1000 + 1, "MM/yyyy"),
                    end_date: format(item3.estimate_time * 1000 + 1000, "MM/yyyy"),
                    parent: secondLayerId,
                    open: true,
                    number: null,
                    status: item3.status,
                    code: item2.code,
                    subCode:item3.code || item3.id
                  });
                  minStatus = minStatus > item3.status ? item3.status : minStatus;
                  superMinStatus = superMinStatus > item3.status ? item3.status : superMinStatus;
                  checkIssue = true;
                }

              });
              result.push({
                id: secondLayerId,
                text: item2.name,
                start_date: null,
                end_date: null,
                parent: item.id,
                open: true,
                number: null,
                code: item2.code,
                status: minStatus,
              });
              result.push({
                id: item.id,
                text: item.name,
                status: superMinStatus,
                type: AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING,
              });
            }
          });
        }
      });
    return { data: result };
  };

  const handleLogout = () => {
    handlingLogout();
    window.location.reload();
  };

  useEffect(() => {
    if (dataGantChat) {
      let result = {
        ...formatData(dataGantChat),
        data: formatData(dataGantChat)?.data?.map(item => ({
          ...item,
          // color: getColorStatus(item.status),
        })),
      };

      setTask(result);
    }
  }, [dataGantChat, issue]);

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box className={classes.logout}>
        <Typography className={classes.containerSubTitle} variant="h5">
          Xiết tiếp xúc
        </Typography>
        <IconButton onClick={handleLogout} className={classes.logoutResponsave}>
          <Logout />
        </IconButton>
      </Box>
      {+roleId === 1 || +roleId === 2 ? (
        <Box className={classes.containerButton}>
          <Button
            onClick={() => setAddReminder(true)}
            color="primary"
            classes={{
              root: classes.addSubButton,
            }}
            variant="contained"
            type="submit"
            endIcon={<Add />}
          >
            Thêm nhắc nhở
          </Button>
          <Button
            onClick={() => setOpen(!open)}
            color="secondary"
            variant="contained"
            type="submit"
            endIcon={<Upload />}
            classes={{ root: classes.borderButon }}
          >
            Tải dữ liệu từ excel
          </Button>
        </Box>
      ) : (
        <></>
      )}
      <Box className={classes.wrapBox}>
        <LocalizationProvider c dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year"]}
            value={value}
            onChange={newValue => {
              setValue(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                helperText={null}
                classes={{
                  root: classes.filterInfo,
                }}
                className="filterInfo"
              />
            )}
          />
        </LocalizationProvider>
        <FormControl className={classes.responsave}>
          <Select defaultValue={"Lựa chọn"} displayEmpty value={status} onChange={handleChange}>
            <MenuItem value={0}>Toàn bộ trạng thái</MenuItem>
            <MenuItem value={2} className={classes.select}>
              <Box className={classes.flexPdf}>
                <Box className={classes.statusOffline} />
                Đang thực hiện
              </Box>
            </MenuItem>
            <MenuItem value={3}>
              <Box className={classes.flexPdf}>
                <Box className={classes.statusOnline} />
                Đã hoàn thành
              </Box>
            </MenuItem>
            <MenuItem value={1}>
              <Box className={classes.flexPdf}>
                <Box className={classes.unfulfilled} />
                Chưa thực hiện
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.responsave}>
          <Select defaultValue={"Lựa chọn"} displayEmpty value={issue} onChange={handleChangeIssue}>
            <MenuItem value={0}>Hạng mục</MenuItem>
            <MenuItem value={6} className={classes.select}>
              <Box className={classes.flexPdf}>Hộp phân dây & Chụp ảnh nhiệt ghíp</Box>
            </MenuItem>
            <MenuItem value={7}>
              <Box className={classes.flexPdf}>XTX hòm công tơ</Box>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField size="small" label="Tìm kiếm" type="search" value={keyword} onChange={handleChangeKeyword} />
        <Box className={classes.containerStatus}>
          <Typography className={classes.status}>Trạng thái</Typography>
          <Box className={classes.containerSubStatus}>
            <Box className={classes.flexPdfStatus}>
              <Box className={classes.unfulfilled} />
              Chưa thực hiện
            </Box>
            <Box className={classes.flexPdfStatusMargin}>
              <Box className={classes.statusOffline} />
              Đang thực hiện
            </Box>
            <Box className={classes.flexPdfStatus}>
              <Box className={classes.statusOnline} />
              Đã hoàn thành
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.heightGantt}>
        {task && <Gantt tasks={task} type={AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING} />}
      </Box>
      <PopupFdf open={open} onClose={() => setOpen(!open)} />
      <AddReminder open={addReminder} onClose={() => setAddReminder(false)} />
    </Container>
  );
};

export default ContactTightening;

const getColorStatus = stt => {
  let color = "#fff";
  if (stt === 1) {
    return (color = "#efb8b3");
  } else if (stt === 2) {
    return (color = "#9abcdb");
  } else if (stt === 3) {
    return (color = "#24c58c");
  }
  return color;
};
const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: "20px",
  },
  responsave: {
    minWidth: 120,
    marginRight: 5,

    [theme.breakpoints.down("360")]: {
      marginTop: 10,
      minWidth: 110,
    },
  },
  borderButon: {
    borderRadius: 10,
  },
  logoutResponsave: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logout: {
    display: "flex",
    justifyContent: "space-between",
  },
  heightGantt: {
    height: "100vh",
  },
  wdithDatePicker: {
    borderRadius: 10,
    height: 30,
  },
  containerButton: {
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  containerStatus: {
    display: "flex",
    marginTop: 24,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  containerSubStatus: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  status: {
    color: "#7F7F80",
    fontSize: 15,
    fontWeight: 700,
  },
  flexPdfStatus: {
    margin: "0 12px",
    display: "flex",
    alignItems: "center",
  },
  flexPdfStatusMargin: {
    margin: "0 12px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "12px 12px",
    },
  },
  addSubButton: {
    marginRight: "10px",
    borderRadius: 10,
  },
  filterInfo: {
    width: "150px",
    paddingRight: 5,
  },
  statusOffline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#9abcdb",
    marginRight: 6,
  },
  unfulfilled: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FF2C28",
    marginRight: 6,
  },
  statusOnline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#24c58c",
    marginRight: 6,
  },
  flexPdf: {
    display: "flex",
    alignItems: "center",
  },
  wrapBox: {
    marginTop: 15,
    marginBottom: 25,
    [theme.breakpoints.down("xs")]: {
      padding: "0px 16px 10px",
      marginBottom: 0,
      marginTop: 0,
    },
  },
  containerSubTitle: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
}));
