import { makeStyles } from "@material-ui/core";
import { Button, Dialog, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import RemindAction from "redux/remind.redux";
import { useState } from "react";
import Cookies from "js-cookie";

const ChangePassword = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accountId = Cookies.get("accountId");

  const [values, setVlues] = useState({
    new_password: "",
    re_password: "",
    current_password: "",
  });

  const changeValue = event => {
    setVlues({ ...values, [event.target.name]: event.target.value });
  };

  const onClickChangePassword = () => {
    if (values.re_password !== values.new_password) {
      alert("mat khau khong giong nhau");
    }
    dispatch(
      RemindAction.apiChangePassword({
        id: accountId,
        new_password: values.new_password,
        current_password: values.current_password,
        re_password: values.re_password,
      }),
    );
    onClose();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="md">
      <Box onClick={onClose} className={classes.flexIcon}>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography sx={{ textAlign: "center" }}>Đổi mật khẩu</Typography>
      <Box className={classes.wrapBox}>
        <Typography className={classes.password}>Mật khẩu hiện tại</Typography>
        <TextField
          inputProps={{
            className: classes.input,
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
          placeholder="Nhập mật khẩu"
          fullWidth
          id="fullWidth"
          name="current_password"
          onChange={changeValue}
        />
        <Typography className={classes.password}>Mật khẩu mới </Typography>
        <TextField
          type="password"
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
          inputProps={{ className: classes.input }}
          placeholder="Nhập mật khẩu mới"
          fullWidth
          id="fullWidth"
          name="new_password"
          onChange={changeValue}
        />
        <Typography className={classes.password}>Nhập lại mật khẩu mới</Typography>
        <TextField
          type="password"
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
          inputProps={{ className: classes.input }}
          placeholder="Nhập lại mật khẩu mới"
          fullWidth
          id="fullWidth"
          name="re_password"
          onChange={changeValue}
        />
      </Box>
      <Box className={classes.buttonPassword}>
        <Button onClick={onClickChangePassword} classes={{ root: classes.button }} variant="contained">
          Lưu
        </Button>
        <Button onClick={onClose} classes={{ root: classes.button }}>
          Hủy
        </Button>
      </Box>
    </Dialog>
  );
};

export default ChangePassword;

const useStyles = makeStyles(theme => ({
  wrapBox: {
    width: "70%",
    margin: "auto",
    paddingBottom: 100,
  },

  password: {
    borderRadius: 10,
    fontSize: 12,
    fontWeight: 600,
    color: "#7F7F80",
    marginBottom: 8,
    marginTop: 8,
  },
  flexIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  input: {
    backgroundColor: "#F5F5F5",
    borderRadius: 10,
  },
  notchedOutline: {
    borderRadius: 10,
  },

  buttonPassword: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    borderRadius: 10,
    width: 200,
    marginBottom: 30,
  },
}));
