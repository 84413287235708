import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import PopupDelete from "./updateDetails/PopupConfirmDelete";
import { format } from "date-fns";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Cookies from "js-cookie";
import { AppConstant } from "const";

const BasicTable = ({ valueTask }) => {
  const classes = useStyles();
  const [openPoupDelete, setOpenPopupDelete] = useState(false);
  const [newTimeArr, setNewTimeArr] = useState([]);
  const [getIddelete, setGetIddelete] = useState();
  const dataPopup = useSelector(state => state.remindRedux.gantChat);
  const roleId = +Cookies.get(AppConstant.ROLE_ID);
  const selectedCode = useSelector(state => state.remindRedux.selectedCode);

  useEffect(() => {
    let tmpArr = [];
    setGetIddelete(dataPopup?.substation);
    dataPopup?.substation?.issues?.forEach(item => {
      if (item?.reminder_details.length > 0) {
        const reminder = item?.reminder_details[item?.reminder_details.length - 1];
        tmpArr.push({
          name: item.name,
          code: item.code,
          reminder_id: item.reminder_id,
          ...reminder,
        });
      }

      return tmpArr;
    });
    setNewTimeArr(tmpArr);
  }, [dataPopup]);

  return (
    <Box className={classes.container}>
      <DialogTitle>
        <Typography className={classes.peopleCreate}> Người tạo </Typography>
        <Typography className={classes.peopleManage}>{`${dataPopup?.user_name} (quản lý) `}</Typography>
      </DialogTitle>
      <DialogContent className={classes.overHidden}>
        {newTimeArr &&
          newTimeArr?.map(remind => {
            if (selectedCode === 8 && remind.code !== 4) {
              return (

                <Box key={remind.id} className={classes.wrapTable}>
                  <Box className={classes.wrapTableSub}>
                    <Box align="center" className={classes.titleTable}>
                      {remind.name}
                    </Box>
                    <Box className={classes.statusTable}>
                      {remind.status === 1 && (
                        <>
                          <Box className={classes.statusOffline1} />
                          Chưa thực hiện
                        </>
                      )}
                      {remind.status === 2 && (
                        <>
                          <Box className={classes.statusOffline} />
                          Đang thực hiện
                        </>
                      )}
                      {remind.status === 3 && (
                        <>
                          <Box className={classes.statusOnline} />
                          Đã hoàn thành
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Typography className={classes.titleColumn}>Thời gian bắt đầu</Typography>
                    <Typography className={classes.statusTable}>
                      {format(remind?.start_time * 1000, "MM/yyyy")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.titleColumn}>Thời gian dự kiến hoàn thành</Typography>
                    <Typography className={classes.statusTable}>
                      {format(
                        ((remind?.new_estimate_time !== null && remind?.new_estimate_time !== 0)
                          ? remind?.new_estimate_time
                          : remind?.estimate_time) * 1000,
                        "MM/yyyy",
                      )}
                    </Typography>
                  </Box>
                </Box>
              );
            } else {
              if (remind.code === 4 && selectedCode !== 8) {
                return (
                  <Box key={remind.id} className={classes.wrapTable}>
                    <Box className={classes.wrapTableSub}>
                      <Box align="center" className={classes.titleTable}>
                        {remind.name}
                      </Box>
                      <Box className={classes.statusTable}>
                        {remind.status === 1 && (
                          <>
                            <Box className={classes.statusOffline1} />
                            Chưa thực hiện
                          </>
                        )}
                        {remind.status === 2 && (
                          <>
                            <Box className={classes.statusOffline} />
                            Đang thực hiện
                          </>
                        )}
                        {remind.status === 3 && (
                          <>
                            <Box className={classes.statusOnline} />
                            Đã hoàn thành
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Typography className={classes.titleColumn}>Thời gian bắt đầu</Typography>
                      <Typography className={classes.statusTable}>
                        {format(remind?.start_time * 1000, "MM/yyyy")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.titleColumn}>Thời gian dự kiến hoàn thành</Typography>
                      <Typography className={classes.statusTable}>
                        {format(
                          ((remind?.new_estimate_time !== null && remind?.new_estimate_time !== 0)
                            ? remind?.new_estimate_time
                            : remind?.estimate_time) * 1000,
                          "MM/yyyy",
                        )}
                      </Typography>
                    </Box>
                  </Box>
                );
              }
            }
          })}
      </DialogContent>
      {(roleId === 1 || roleId === 2) && valueTask.$level === 1 ? (
        <DialogActions className={classes.buttonTable}>
          <Button
            className={classes.buttonTableSub}
            onClick={() => setOpenPopupDelete(true)}
            variant="contained"
            color="error"
          >
            Xóa nhắc nhở
          </Button>
        </DialogActions>
      ) : (
        <></>
      )}
      <PopupDelete data={getIddelete} open={openPoupDelete} onClose={() => setOpenPopupDelete(false)} />
    </Box>
  );
};
export default BasicTable;

const useStyles = makeStyles(theme => ({
  wrapTable: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  titleColumn: {
    color: "#7F7F80",
    fontSize: 12,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  container: {
    minWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: "80%",
      overflow: "hidden",
    },
  },
  fontSizeTable: {
    fontSize: 12,
    color: "#7F7F80",
  },
  titleTable: {
    color: "#7F7F80",
    fontSize: 12,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      textAlign: "start",
    },
  },
  wrapTableSub: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 12,
    },
  },
  statusTable: {
    display: "flex",
    alignItems: "center",
    color: "#2E2E2E",
    fontSize: 15,
    padding: "8px 8px 8px 0",
  },
  statusOffline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#285AC6",
    marginRight: 6,
  },
  statusOffline1: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FF2C28",
    marginRight: 6,
  },
  statusOnline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#35CC3F",
    marginRight: 6,
  },
  wrapTile: {
    display: "flex",
    flexDirection: "column",
  },
  buttonTable: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 0",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  timeTable: {
    color: "#7F7F80",
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      textAlign: "start",
    },
  },
  peopleCreate: {
    color: "#7F7F80",
    fontSize: 12,
  },
  peopleManage: {
    color: "#000",
    fontSize: 15,
  },
  overHidden: {
    height: 500,
    width: 500,
    overflowY: "auto",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("xs")]: {
      height: 300,
      overflowY: "auto",
    },
  },
  buttonTableSub: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
