import React, { useEffect, useState } from "react";
import { Container, Typography, Box, Button, TextField } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Add } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import Gantt from "../../Gantt";
import RemindAction from "redux/remind.redux";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch } from "react-redux";
import PostReport from "./postreport";
import AddReminder from "./AddReminder";
import { format } from "date-fns";
import Cookies from "js-cookie";
import { AppConstant } from "const";

const Experiment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataGantChat = useSelector(state => state.remindRedux.dataGantt);
  const isSuccessDelete = useSelector(state => state.remindRedux.isSuccessDelete);
  const isFetch = useSelector(state => state.remindRedux.isSuccess);
  const roleId = Cookies.get("role-id");

  const [value, setValue] = useState(new Date());
  const [status, setStatus] = useState(0);
  const [task, setTask] = useState(null);
  const [addReminder, setAddReminder] = useState(false);
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState(null);

  const handleChangeKeyword = event => {
    setKeyword(event.target.value);
  };
  const onClickRemind = async () => {
    await setAddReminder(true);
  };

  useEffect(() => {
    const handlerTimeout = setTimeout(() => {
      dispatch(
        RemindAction.getGantChatApi({
          estimate_year: value.getFullYear(),
          status: status,
          type: 2,
          keyword: keyword,
        }),
      );
    }, 500);

    return () => clearTimeout(handlerTimeout);
  }, [value, status, keyword]);

  useEffect(() => {
    dispatch(
      RemindAction.conversationSet({
        dateYearTightening: value.getFullYear(),
      }),
    );
  }, [value]);

  useEffect(() => {
    dispatch(
      RemindAction.remindSet({
        selectedCode: 4,
      }),
    );
  });

  useEffect(() => {
    if (isSuccessDelete || isFetch) {
      dispatch(
        RemindAction.getGantChatApi({
          estimate_year: value.getFullYear(),
          status: status,
          type: 2,
        }),
      );
      dispatch(RemindAction.resetRedux());
    }
  }, [isSuccessDelete, isFetch]);

  const formatData = data => {
    let result = [];
    data &&
      data?.forEach(item => {
        let minGroupStatus = 3;
        if (item?.substation?.length > 0) {
          item?.substation?.forEach(item2 => {
            let minSubStatus = 3;
            let secondLayerId = `${item.id}-${item2.id}`;

            if (item2?.issue?.length > 0) {
              item2?.issue?.forEach(item3 => {
                const date = new Date(item3?.start_time * 1000);
                const month = date.getMonth() + 1 === 12 ? 1 : date.getMonth() + 2;
                const year = date.getMonth() + 1 === 12 ? date.getFullYear() + 1 : date.getFullYear();
                let thirdLayerId = `${secondLayerId}-${item3.id}`;
                minGroupStatus = Math.min(minGroupStatus, item3.status);
                minSubStatus = Math.min(minSubStatus, item3.status);
                let estimate_date = new Date(item3.estimate_time * 1000);
                result.push({
                  id: secondLayerId,
                  text: item2.name,
                  start_date: format(item3.start_time * 1000, "MM/yyyy"),
                  end_date: format(item3.estimate_time * 1000 + 1000, "MM/yyyy"),
                  parent: item.id,
                  open: true,
                  number: null,
                  status: item3.status,
                  code: item2.code,
                  subCode: item3.code || item3.id,
                });
              });
            }
            // result.push({
            //   id: secondLayerId,
            //   text: item2.name,
            //   start_date: null,
            //   end_date: null,
            //   parent: item.id,
            //   open: true,
            //   number: null,
            //   code: item2.code,
            //   status: minSubStatus,
            // });
          });
        }
        result.push({
          id: item.id,
          text: item.name,
          status: minGroupStatus,
          type: AppConstant.ISSUE_TYPE.EXPERIMENT,
        });
      });

    return { data: result };
  };

  useEffect(() => {
    if (dataGantChat) {
      let result = {
        ...formatData(dataGantChat),
        data: formatData(dataGantChat).data.map(item => ({
          ...item,
          // color: getColorStatus(item.status),
        })),
      };

      setTask(result);
    }
  }, [dataGantChat]);

  const handleChange = event => {
    setStatus(event.target.value);
  };

  return (
    <Container maxWidth={false}>
      <Typography variant="h5">Thí nghiệm</Typography>
      {+roleId === 1 || +roleId === 2 ? (
        <Box justifyContent="space-between" display="flex">
          <Button
            onClick={onClickRemind}
            color="primary"
            classes={{
              root: classes.addSubButton,
            }}
            variant="contained"
            type="submit"
            endIcon={<Add />}
          >
            Thêm nhắc nhở
          </Button>
        </Box>
      ) : (
        <></>
      )}
      <Box className={classes.wrapBox}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year"]}
            // label="Year only"
            value={value}
            onChange={newValue => {
              setValue(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                helperText={null}
                classes={{
                  root: classes.filterInfo,
                }}
                className="filterInfo"
              />
            )}
          />
        </LocalizationProvider>
        <FormControl sx={{ minWidth: 120, marginRight: 0.5 }}>
          <Select defaultValue={"Lựa chọn"} displayEmpty value={status} onChange={handleChange}>
            <MenuItem value={0}>Toàn bộ trạng thái</MenuItem>
            <MenuItem value={2} className={classes.select}>
              <Box className={classes.flexPdf}>
                <Box className={classes.statusOffline} />
                Đang thực hiện
              </Box>
            </MenuItem>
            <MenuItem value={3}>
              <Box className={classes.flexPdf}>
                <Box className={classes.statusOnline} />
                Đã hoàn thành
              </Box>
            </MenuItem>
            <MenuItem value={1}>
              <Box className={classes.flexPdf}>
                <Box className={classes.unfulfilled} />
                Chưa thực hiện
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField size="small" label="Tìm kiếm" type="search" value={keyword} onChange={handleChangeKeyword} />
        <Box className={classes.containerStatus}>
          <Typography className={classes.status}>Trạng thái</Typography>
          <Box className={classes.flexPdfStatus}>
            <Box className={classes.unfulfilled} />
            Chưa thực hiện
          </Box>
          <Box className={classes.flexPdfStatus}>
            <Box className={classes.statusOffline} />
            Đang thực hiện
          </Box>
          <Box className={classes.flexPdfStatus}>
            <Box className={classes.statusOnline} />
            Đã hoàn thành
          </Box>
        </Box>
      </Box>

      <Box className={classes.heightGantt}>
        {task && <Gantt tasks={task} type={AppConstant.ISSUE_TYPE.EXPERIMENT} />}
      </Box>
      <PostReport onClose={() => setOpen(!open)} open={open} />
      <AddReminder open={addReminder} onClose={() => setAddReminder(false)} />
    </Container>
  );
};

export default Experiment;

const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: "20px",
  },
  responsave: {
    minWidth: 120,

    [theme.breakpoints.down("360")]: {
      marginTop: 10,
      minWidth: 110,
    },
  },
  borderButon: {
    borderRadius: 10,
  },
  logoutResponsave: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logout: {
    display: "flex",
    justifyContent: "space-between",
  },
  heightGantt: {
    height: "100vh",
  },
  wdithDatePicker: {
    borderRadius: 10,
    height: 30,
  },
  containerButton: {
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  containerStatus: {
    display: "flex",
    marginTop: 24,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  containerSubStatus: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  status: {
    color: "#7F7F80",
    fontSize: 15,
    fontWeight: 700,
  },
  flexPdfStatus: {
    margin: "0 12px",
    display: "flex",
    alignItems: "center",
  },
  flexPdfStatusMargin: {
    margin: "0 12px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "12px 12px",
    },
  },
  addSubButton: {
    marginRight: "10px",
    borderRadius: 10,
  },
  filterInfo: {
    width: "150px",
    paddingRight: 4,
  },
  statusOffline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#9abcdb",
    marginRight: 6,
  },
  unfulfilled: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FF2C28",
    marginRight: 6,
  },
  statusOnline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#24c58c",
    marginRight: 6,
  },
  flexPdf: {
    display: "flex",
    alignItems: "center",
  },
  wrapBox: {
    marginTop: 15,
    marginBottom: 25,
    [theme.breakpoints.down("xs")]: {
      padding: "0px 16px 10px",
      marginBottom: 0,
      marginTop: 0,
    },
  },
  containerSubTitle: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
}));
