import { createReducer, createActions } from "reduxsauce";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getFile: ["data"],
  uploadFileRemind: ["data"],
  uploadFileSubstation: ["data"],
  fileSuccess: ["data"],
  fileFailure: ["error"],
  fileSet: ["data"],
  resetRedux: ["data"],
});
export const FileTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: null,
  isFetching: false,
  isSuccess: false,
  isGetFileSuccess: false,
  uploadFileSuccess: false,
  error: null,
  errorPostFile: null,
};
/* ------------- Reducers Waiting Consumer ------------- */
export const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetching: true,
  };
};
export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  ...action.data,
});
export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  ...action.error,
});
export const set = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  errorPostFile: null,
});
/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_FILE]: request,
  [Types.UPLOAD_FILE_REMIND]: request,
  [Types.UPLOAD_FILE_SUBSTATION]: request,

  [Types.FILE_SUCCESS]: success,
  [Types.FILE_FAILURE]: failure,

  [Types.RESET_REDUX]: reset,
  [Types.FILE_SET]: set,
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
