import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "./redux";

const theme = createTheme({
  palette: {
    primary: {
      main: "#285AC6",
    },
    secondary: {
      main: "#FF2C28",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "10px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: "10px",
          marginBottom: "10px",
        },
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);
