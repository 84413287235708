import { createReducer, createActions } from "reduxsauce";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getUserList: [],
  createUser: ["data"],
  deleteUser: ["data"],
  updateUser: ["data"],

  userSuccess: ["data"],
  userFailure: ["error"],
  userSet: ["data"],
});
export const UserTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: [],
  isFetching: false,
  isSuccess: false,
  error: null,
  isCreateSuccess: false,
  isDeleteSuccess: false,
};
/* ------------- Reducers Waiting Consumer ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});
export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  ...action.data,
});
export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  ...action.error,
});
export const set = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});
/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_USER_LIST]: request,
  [Types.CREATE_USER]: request,
  [Types.DELETE_USER]: request,
  [Types.UPDATE_USER]: request,

  [Types.USER_SUCCESS]: success,
  [Types.USER_FAILURE]: failure,
  [Types.USER_SET]: set,
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
