import { call, put } from "redux-saga/effects";
import SubstationAction from "redux/substation.redux";
import { SubstationService } from "services";

export function* createSubstation(action) {
  try {
    const { data } = action;

    let response = yield call(SubstationService.createSubstation, data);
    if (response.status === 200) {
      yield put(
        SubstationAction.substationSet({
          isCreateSuccess: true,
        }),
      );
    } else {
      yield put(SubstationAction.substationFailure(response.data));
    }
  } catch (error) {
    yield put(SubstationAction.substationFailure(error));
  }
}

export function* getListSubstation(action) {
  try {
    const { data } = action;

    let response = yield call(SubstationService.getListSubstation, data);
    if (response.status === 200) {
      yield put(
        SubstationAction.substationSet({
          substationList: response.data.data,
        }),
      );
    } else {
      yield put(SubstationAction.substationFailure(response.data));
    }
  } catch (error) {
    yield put(SubstationAction.substationFailure(error));
  }
}

export function* deleteSubstation(action) {
  try {
    const { data } = action;
    let response = yield call(SubstationService.deleteSubstation, { codes: data.codes.join() });

    if (response.status === 200) {
      yield put(
        SubstationAction.substationSet({
          isDeleteSuccess: true,
        }),
      );
    } else {
      yield put(SubstationAction.substationFailure(response.data));
    }
  } catch (error) {
    yield put(SubstationAction.substationFailure(error));
  }
}

export function* updateSubstation(action) {
  try {
    const { data } = action;
    let response = yield call(SubstationService.updateSubstation, data);
    if (response.status === 200) {
      yield put(
        SubstationAction.substationSet({
          isUpdateSuccess: true,
        }),
      );
    } else {
      yield put(SubstationAction.substationFailure(response.data));
    }
  } catch (error) {
    yield put(SubstationAction.substationFailure(error));
  }
}
