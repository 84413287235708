import * as React from "react";
import Dialog from "@mui/material/Dialog";
import imagePopup from "theme/img/IconConfirm.png";
import { Box } from "@mui/system";
import { Button, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const ConfirmPopup = ({ onClose, open, setConfirm }) => {
  const classes = useStyles();
  const onClickHiiden = () => {
    setConfirm();
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={setConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.poupDelete}>
        <CardMedia sx={{ width: "100px", margin: "auto", paddingTop: 2 }} component="img" src={imagePopup} />
        <Typography className={classes.titleDelete}>
          Trạm “Trạm Liên Việt” đã thay định kỳ trong năm nay Vui lòng thử lại
        </Typography>
        <Button onClick={onClickHiiden} className={classes.marginButton} variant="contained">
          Có
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmPopup;
const useStyles = makeStyles(theme => ({
  poupDelete: {
    minWidth: 500,
    textAlign: "center",
  },
  titleDelete: {
    color: "#2E2E2E",
    width: 380,
    margin: "auto",
    padding: "20px 0 40px 0",
  },
  buttonDelete: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 35,
  },
  marginButton: {
    width: 150,
  },
}));
