import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import RepairPopup from "./updateDetails/RepairPopup";
import PopupDelete from "./updateDetails/PopupConfirmDelete";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core";
import { AppConstant } from "const";
import Cookies from "js-cookie";

const DetailsEachOne = ({ data, onClose, currentdata }) => {
  const classes = useStyles();
  const [openEachOne, setOpenEachOne] = useState(false);
  const [newTimeArr, setNewTimeArr] = useState([]);
  const [openPoupDelete, setOpenPopupDelete] = useState(false);
  const roleId = +Cookies.get(AppConstant.ROLE_ID);

  const onClickDelete = () => {
    setOpenPopupDelete(true);
    setOpenEachOne(false);
  };

  useEffect(() => {
    const newTime =
      data &&
      data?.reminder_details?.sort((a, b) => new Date(b.update_time !== null ? b.update_time : 0).getTime() - new Date(a.update_time).getTime())[0];
    setNewTimeArr(newTime);
  }, [data]);
  return (
    <>
      <Box className={classes.scrollHeight}>
        {data?.reminder_details?.map((item, index, elment) => {
          {
            return <Box
                key={item.id}
                className={newTimeArr?.update_time === item?.update_time ? classes.wrapWidth : classes.wrapWidthBackground}
            >
              <Typography>{format(item?.created * 1000, "HH:mm dd/MM/yyyy")}</Typography>
              <Box className={classes.statusTable}>
                {item.status === 3 && (
                    <>
                      <Box className={classes.statusOnline}/>
                      Đã hoàn thành
                    </>
                )}
                {item.status === 2 && (
                    <>
                      <Box className={classes.statusOffline}/>
                      Đang thực hiện
                    </>
                )}
                {item.status === 1 && (
                    <>
                      <Box className={classes.statusOffline1}/>
                      Chưa thực hiện
                    </>
                )}
              </Box>
              <Typography className={classes.titleName}>{item?.user?.fullname}</Typography>
              <Box className={classes.statusTime}>
                Thời gian dự kiến hoàn thành :
                <Box className={classes.containerTimeEstimate}>
                  <Typography className={classes.statusTimeColor}>
                    {format(item?.estimate_time * 1000, "MM/yyyy")}
                  </Typography>
                  <ArrowRightAltIcon style={{color: "#285AC6"}}/>
                  {(item?.new_estimate_time === 0 || item?.new_estimate_time == null) ? (
                      <Typography className={classes.statusTimeColor}>
                        {format(item?.estimate_time * 1000, "MM/yyyy")}
                      </Typography>
                  ) : (
                      <Typography className={classes.statusTimeColor}>
                        {format(item?.new_estimate_time * 1000, "MM/yyyy")}
                      </Typography>
                  )}
                </Box>
              </Box>
              <Box className={classes.statusTime}>
                <Typography>Trạng thái :</Typography>
                <Box className={classes.statusTimeSub}>
                  {elment[index + 1]?.status === 1 && (
                      <Typography className={classes.statusTimeColor}>Chưa thực hiện </Typography>
                  )}
                  {elment[index + 1]?.status === 2 && (
                      <Typography className={classes.statusTimeColor}>Đang thực hiện </Typography>
                  )}
                  {elment[index + 1]?.status === 3 && (
                      <Typography className={classes.statusTimeColor}>Đã hoàn thành </Typography>
                  )}
                  {elment[index + 1]?.status && <ArrowRightAltIcon style={{color: "#285AC6"}}/>}
                  {item.status === 1 && <Typography className={classes.statusTimeColor}>Chưa thực hiện </Typography>}
                  {item.status === 2 && <Typography className={classes.statusTimeColor}>Đang thực hiện </Typography>}
                  {item.status === 3 && <Typography className={classes.statusTimeColor}>Đã hoàn thành </Typography>}
                </Box>
              </Box>
              <TextField
                  disabled={true}
                  variant="outlined"
                  label="Ghi chú"
                  defaultValue={item?.note}
                  id="reddit-input"
                  fullWidth
                  style={{marginTop: 11}}
                  type="text"
              />
            </Box>
          }
        })}
      </Box>
      <Box className={classes.flexButton}>
        <Button onClick={() => setOpenEachOne(!openEachOne)} className={classes.marginButton} variant="contained">
          Cập nhật
        </Button>
        {roleId === 1 || roleId === 2 ? (
          <Button color="error" className={classes.buttonResponsive} onClick={onClickDelete} variant="contained">
            Xóa nhắc nhở
          </Button>
        ) : (
          <></>
        )}
      </Box>
      <RepairPopup
        currentdata={currentdata}
        data={data}
        newTimeArr={newTimeArr}
        onClose={onClose}
        open={openEachOne}
        setOpenEachOne={setOpenEachOne}
      />
      <PopupDelete
        data={currentdata.substation}
        newTimeArr={newTimeArr}
        open={openPoupDelete}
        onClose={() => setOpenPopupDelete(false)}
      />
    </>
  );
};

export default DetailsEachOne;

const useStyles = makeStyles(theme => ({
  statusTable: {
    display: "flex",
    alignItems: "center",
    color: "#2E2E2E",
    fontSize: 15,
    padding: "8px 8px 8px 0",
  },
  statusOffline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#285AC6",
    marginRight: 6,
  },
  statusOffline1: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FF2C28",
    marginRight: 6,
  },
  statusOnline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#35CC3F",
    marginRight: 6,
  },
  flexUpdate: {
    display: "flex",
    marginLeft: 20,
  },
  fdfDetails: {
    color: "#2E2E2E",
    fontSize: 12,
  },
  wrapFdfDetails: {
    marginLeft: 8,
  },
  titleName: {
    marginLeft: 20,
    fontSize: 15,
    marginBottom: 8,
    color: "#000000",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },
  wrapWidth: {
    backgroundColor: "#F5F5F5",
    borderRadius: 20,
    padding: "8px 20px",
    border: "1px solid #285AC6",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      padding: "10px 15px",
    },
  },
  buttonResponsive: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  wrapWidthBackground: {
    backgroundColor: "#fafafa",
    borderRadius: 20,
    padding: "8px 20px",
    marginTop: 10,
  },
  scrollHeight: {
    height: 530,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      height: 300,
      overflowY: "scroll",
    },
  },
  flexButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,
    marginRight: 30,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginRight: 0,
    },
  },
  marginButton: {
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  statusTime: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginLeft: 10,
      alignItems: "start",
    },
  },
  statusTimeSub: {
    display: "flex",
  },
  statusTimeColor: {
    color: "#285AC6",
    fontSize: 15,
    marginLeft: 3,
    fontWeight: 400,
  },
  containerTimeEstimate: {
    display: "flex",
  },
}));

//TODO: confirm api file BE
// <Box className={classes.flexUpdate}>
//   <CardMedia sx={{ width: 26, height: 30 }} component="img" image={filePreview} />
//   <Box className={classes.wrapFdfDetails}>
//     <Typography className={classes.fdfDetails}>{item?.file_upload}</Typography>
//     <Typography className={classes.fdfDetails}>12 MB</Typography>
//   </Box>
// </Box>
