import { format as DateFormat } from "date-fns";

export const getTimestamp = date => {
  let tmpDate = new Date(date);
  return Math.floor(tmpDate.getTime() / 1000);
};

export const convertTimeStampToDate = (timeStamp, formatConstant) => {
  let resultDate;
  if (timeStamp) {
    if (formatConstant) {
      resultDate = DateFormat(new Date(timeStamp * 1000), formatConstant);
    } else {
      resultDate = new Date(timeStamp * 1000);
    }
  }
  return resultDate;
};

export function formatSizeUnits(bytes) {
  if (bytes) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  }
  return bytes;
}
