import React, { Fragment, useContext, useEffect, useState } from "react";
import "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { gantt } from "dhtmlx-gantt";
import { makeStyles } from "@material-ui/core/styles";
import RemindAction from "redux/remind.redux";
import { useDispatch, useSelector } from "react-redux";
import DialogDetails from "components/experiment/Reminderdetails/DialogDetails";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { Search } from "@mui/icons-material";
import { getFilterArray } from "utils";
import { useDebounce } from "utils";
import "./style.css";
import { getCreateTimeObject } from "utils";
import TaskContext from "context/task/task.context";
import { AppConstant } from "const";

const Gantt = ({ tasks, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUpdateSuccess = useSelector(state => state.remindRedux.isUpdateSuccess);
  const isSuccessDelete = useSelector(state => state.remindRedux.isSuccessDelete);
  const dataGantChat = useSelector(state => state.remindRedux.dataGantt);
  const dateYearTightening = useSelector(state => state.remindRedux.dateYearTightening);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({});
  const [data, setData] = useState(null);
  const [valueSearchMobile, setValueSearchMobile] = useState("");
  const [dataGanttResponsave, setDataGanttResponsave] = useState();
  const [objKey, setObjKey] = useState();

  const taskCtx = useContext(TaskContext);

  useEffect(() => {
    if (value.code) {
      dispatch(
        RemindAction.remindDetails({
          code: value?.code,
          year: dateYearTightening,
          issueType: type,
        }),
      );
      setOpen(true);
      gantt.resetLayout();
      gantt.render();
    }
  }, [value.code]);

  useEffect(() => {
    gantt.clearAll();
    gantt.resetLayout();
  }, []);

  useEffect(() => {
    if (isSuccessDelete) {
      setOpen(false);
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    setData(tasks);
    gantt.clearAll();
    gantt.render();
  }, [tasks, data]);

  useEffect(() => {
    let result = [];
    dataGantChat.forEach(item => {
      item.substation.forEach(substations => {
        substations.issue.forEach(issue => {
          result.push({
            status: issue.status,
            code: substations.code,
            name: substations.name,
            start_time: issue.start_time,
          });
        });
      });
    });
    setDataGanttResponsave(result);
  }, [dataGantChat]);

  useEffect(() => {
    if (data) {
      gantt.config.columns = [
        {
          name: "text",
          label: "Đội",
          tree: true,
          width: "*",
          resize: true,
        },
        {
          name: "status",
          label: "Trạng thái",
          align: "center",
          width: "120",
          resize: true,
          template: function (obj) {
            if (obj.status === 1 && !obj.parent) {
              return (
                "<span style='width:15px; height:15px;color:#ff2c28;animation:blinker 1s linear infinite'>" +
                "Chưa hoàn thành" +
                "</span>"
              );
            }
          },
        },
      ];
      gantt.templates.task_text = function (start, end, task) {
        if (task.status) return "<span style='color: #000; overflow:auto;'>" + task.text + "</span>";
      };
      gantt.config.scales = [
        { unit: "month", step: 1, format: "%M" },
        { unit: "year", step: 1, format: "%Y" },
      ];
      gantt.i18n.setLocale({
        date: {
          month_short: ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9", "T10", "T11", "T12"],
        },
      });

      gantt.config.editable_property = "property_name";
      gantt.config.details_on_dblclick = false;
      gantt.config.drag_progress = false;
      gantt.config.drag_links = false;
      gantt.config.date_format = "%m-%Y";
      gantt.init("gantt_here");
      gantt.parse(data);

      if (type === AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING) {
        taskCtx.tasksCT.forEach(item => gantt.open(item));
      }
      if (type === AppConstant.ISSUE_TYPE.EXPERIMENT) {
        taskCtx.tasksExperiment.forEach(item => gantt.open(item));
      }

      gantt.attachEvent("onTaskSelected", function (id) {
        let current = gantt.getTask(id);
        if (current.code) setOpen(true);
        setValue(gantt.getTask(id));
      });
    }
    gantt.attachEvent("onBeforeTaskSelected", function (id) {
      let current = gantt.getTask(id);
      if (current.code) setOpen(true);
      return true;
    });
    gantt.attachEvent("onTaskClosed", function (id) {
      let current = gantt.getTask(id);
      if (current.type === AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING) {
        taskCtx.removeTask(id, AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING);
      } else {
        taskCtx.removeTask(id, AppConstant.ISSUE_TYPE.EXPERIMENT);
      }
      if (current.code) setOpen(false);
    });
    gantt.attachEvent("onTaskOpened", function (id) {
      let current = gantt.getTask(id);
      if (current.type === AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING) {
        taskCtx.addTask(id, AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING);
      } else {
        taskCtx.addTask(id, AppConstant.ISSUE_TYPE.EXPERIMENT);
      }
      if (current.code) setOpen(false);
    });
    gantt.attachEvent("onTaskClick", function (id, e) {
      let current = gantt.getTask(id);
      if (current.code) {
        dispatch(
          RemindAction.remindSet({
            selectedSubCode: current.subCode,
          }),
        );
        setOpen(true);
      }
      return true;
    });
  }, [data, tasks]);

  useEffect(() => {
    gantt.templates.task_class = function (start, end, task) {
      // eslint-disable-next-line default-case
      switch (task.status) {
        case 1:
          return "high";
          break;
        case 2:
          return "medium";
          break;
        case 3:
          return "low";
          break;
      }
      if (!task.status) {
        return "wgdf";
      }
    };
  }, []);

  useEffect(() => {
    gantt.attachEvent("onTaskClick", function (id, e) {
      let current = gantt.getTask(id);
      if (current.code) setOpen(true);
      return true;
    });
  }, []);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        RemindAction.remindSet({
          isUpdateSuccess: false,
        }),
      );
      setOpen(false);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (!open) {
      setValue({});
    }
    if (!dataGantChat) {
      setValue({});
    }
  }, [open, dataGantChat]);
  const handlerDebound = useDebounce(valueSearchMobile, 500);

  useEffect(() => {
    setObjKey(getCreateTimeObject(dataGanttResponsave));
  }, [dataGanttResponsave]);

  return (
    <>
      <div id="gantt_here" className={classes.task} />
      <Box className={classes.containerSearch}>
        <TextField
          onChange={e => setValueSearchMobile(e.target.value)}
          value={valueSearchMobile}
          placeholder="Search"
          className={classes.root}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
          id="outlined-basic"
          fullWidth
          variant="outlined"
        />
      </Box>
      <Box className={classes.heightOverfolow}>
        {objKey &&
          Object.keys(objKey).map((resultItems, index) => (
            <Fragment key={index}>
              <Box className={classes.wrapChat}>
                <Box className={classes.titleOnline} variant="p" component="h2">
                  <Typography className={classes.title}>{resultItems}</Typography>
                </Box>
              </Box>
              {getFilterArray(objKey[resultItems], handlerDebound).map(resultItem => (
                <Box key={index} className={classes.container}>
                  <Box className={classes.containerPadding} onClick={() => setValue(resultItem)}>
                    <Typography className={classes.containerSubText}>{resultItem.name}</Typography>
                    <Typography className={classes.statusTime}>
                      {format(resultItem.start_time * 1000, "MM/yyyy")}
                    </Typography>
                    {resultItem.status === 1 && (
                      <Box className={classes.flexPdfStatus}>
                        <Box className={classes.unfulfilled} />
                        Chưa thực hiện
                      </Box>
                    )}
                    {resultItem.status === 2 && (
                      <Box className={classes.flexPdfStatus}>
                        <Box className={classes.statusOffline} />
                        Đang thực hiện
                      </Box>
                    )}
                    {resultItem.status === 3 && (
                      <Box className={classes.flexPdfStatus}>
                        <Box className={classes.statusOnline} />
                        Đã hoàn thành
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Fragment>
          ))}
      </Box>

      <DialogDetails open={open} onClose={() => setOpen(!open)} value={value} />
    </>
  );
};

export default Gantt;

const useStyles = makeStyles(theme => ({
  task: {
    width: "99%",
    height: "700px",
    color: "red",
    progressColor: "red",
    progressSelectedColor: "#ff9e0d",
    overfollow: "auto",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  wrapChat: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: 19,
    border: "1px solid #F5F5F5",
    width: "50%",
    textAlign: "center",
    justifyItems: "center",
    margin: "25px auto",
  },
  titleOnline: {
    position: "absolute",
    backgroundColor: theme.palette.grey[100],
    marginTop: "-16px",
    borderRadius: "20px",
    border: "1px solid #ccc",
  },
  title: {
    color: "#424242",
    padding: "3px 12px",
  },
  containerSearch: {
    padding: "0 30px 5px",
  },
  statusTime: {
    color: "#7F7F80",
    fontSize: 12,
    padding: "6px 0",
  },
  selection: {
    border: "1px solid black",
    borderRadius: 10,
  },

  selectionContainer: {
    display: "flex",
  },
  heightOverfolow: {
    height: "67vh",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  datePickerContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  },

  flexPdfStatus: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    [`& fieldset`]: {
      borderRadius: 15,
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  statusOffline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#285AC6",
    marginRight: 6,
  },

  unfulfilled: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FF2C28",
    marginRight: 6,
    animation: `$blinker 1s linear infinite`,
  },
  "@keyframes blinker": {
    "0%": {
      opacity: 0.2,
    },
    "50%": {
      opacity: 0.6,
    },
    "100%": {
      opacity: 1,
    },
  },

  statusOnline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#35CC3F",
    marginRight: 6,
  },

  container: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    marginTop: 12,

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  containerPadding: {
    padding: "12px 16px",
  },
  containerSubText: {
    color: "#2E2E2E",
    fontSize: 15,
    fontWeight: 600,
  },
}));
