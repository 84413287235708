import { AppConstant } from "const";

const { useReducer } = require("react")
const { default: TaskContext } = require("./task.context")

const initialTaskState = {
    tasksCT: [],
    tasksExperiment: []
}

const taskReducer = (state, action) => {
    if(action.type ==="ADD_TASK") {
        if(action.typeTask === AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING) {
            var index = state.tasksCT.indexOf(action.id);
            if (index > -1) {
                return state;
            }
            state.tasksCT.push(action.id);
            return state;
        } 
        if(action.typeTask === AppConstant.ISSUE_TYPE.EXPERIMENT) {
            var index = state.tasksExperiment.indexOf(action.id);
            if (index > -1) {
                return state;
            }            
            state.tasksExperiment.push(action.id);
            return state;
        }
    }
    if(action.type ==="REMOVE_TASK") {
        if(action.typeTask === AppConstant.ISSUE_TYPE.CONTACT_TIGNTENING){
            var index = state.tasksCT.indexOf(action.id);
            if (index > -1) {
                state.tasksCT.splice(index, 1);
                return state;
            }
            
            return initialTaskState;
            
        }
        if(action.typeTask === AppConstant.ISSUE_TYPE.EXPERIMENT){
            var index = state.tasksExperiment.indexOf(action.id);
            if (index > -1) {
                state.tasksExperiment.splice(index, 1);
                return state;
            }
            return initialTaskState;
        }     
    }
    return initialTaskState;
}

const TaskProvider = (props) =>{

    const [taskState, dispatchTaskAction] = useReducer(taskReducer, initialTaskState)

    const addTask = (id, type) => {
        dispatchTaskAction({
            type:"ADD_TASK",
            id: id,
            typeTask: type
        })
    }

    const removeTask = (id, type) => {
        dispatchTaskAction({
            type:"REMOVE_TASK",
            id: id,
            typeTask: type
        })
    }

    const taskContext = {
        tasksCT: taskState.tasksCT,
        tasksExperiment: taskState.tasksExperiment,
        addTask: addTask,
        removeTask: removeTask
    }

    return(
        <TaskContext.Provider value={taskContext}>{props.children}</TaskContext.Provider>
    )
}

export default TaskProvider;