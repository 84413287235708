/* eslint-disable react/jsx-no-comment-textnodes */
import { Button, CardMedia, Dialog, IconButton, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
import UploadIcon from "@mui/icons-material/Upload";
import imageIcloud from "theme/img/uploadCloud.png";
import imageUpdateDone from "theme/img/Frame.svg";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileAction from "redux/file.redux";

const PostReport = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [valueInput, setValueInput] = useState();
  const [popupFile, setPopupFile] = useState(true);
  const [onSelecctFile, setOnSelecctFile] = useState();
  const [openPopupMutiFile, setOpenPopupMutiFile] = useState(false);

  const uploadFileSuccess = useSelector(state => state.fileRedux.uploadFileSuccess);

  const progressRef = useRef(() => {});

  const onChangeFile = e => {
    setValueInput(e.target.files[0]);
  };

  const onClickFile = e => {
    if (valueInput) {
      setPopupFile(false);
    } else {
      e.preventDefault();
    }
  };

  const onChangeMutifile = e => {
    setOnSelecctFile(e.target.files[0]);
  };

  const onClickFileMuTi = e => {
    if (onSelecctFile) {
      setOpenPopupMutiFile(onSelecctFile);
    } else {
      e.preventDefault();
      setOpenPopupMutiFile(false);
    }
  };

  const handleUploadFilePdf = () => {
    dispatch(FileAction.uploadFileSubstation(valueInput));
  };

  useEffect(() => {
    if (uploadFileSuccess) {
      dispatch(
        FileAction.setFile({
          uploadFileSuccess: false,
        }),
      );
      onClose();
    }
  }, [uploadFileSuccess]);

  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="lg">
      <Box onClick={onClose} className={classes.iconFlex}>
        <IconButton>
          <CloseIcon classes={{ root: classes.colorIcon }} />
        </IconButton>
      </Box>
      <Box className={classes.paddingWidth}>
        <Box className={classes.handlerWidth}>
          <Box className={classes.titlepdf}>
            <Typography className={classes.uploadFile}>Đăng biên bản</Typography>
          </Box>

          {popupFile ? (
            <form id="form-file-upload" className={classes.dotterBorder} onSubmit={e => e.preventDefault()}>
              <input
                accept="application/pdf"
                style={{ display: "none" }}
                id="input-file-upload"
                multiple
                type="file"
                onChange={onChangeFile}
              />
              <label className={classes.labelInput} id="label-file-upload" htmlFor="input-file-upload">
                <CardMedia className={classes.imageCloudPdf} component="img" image={imageIcloud} />
                <Typography className={classes.chosseFilePdf}>Chọn file hoặc kéo thả vào màn hình</Typography>
              </label>
              <Typography className={classes.downloadFdf}>
                Lưu ý: Tải file lên theo định dạng “Tên trạm + hạng mục thí nghiệm.pdf”
              </Typography>
              <Button
                onClick={onClickFile}
                color="secondary"
                variant="contained"
                className={classes.buttonDownload}
                endIcon={<UploadIcon />}
              >
                Đăng dữ liệu
              </Button>
            </form>
          ) : (
            <>
              <form className={classes.dooterChooseFile} id="form-file-upload" onSubmit={e => e.preventDefault()}>
                <Box className={classes.flexChoosefile}>
                  <input
                    accept="application/pdf"
                    style={{ display: "none" }}
                    id="input-file-upload"
                    multiple
                    type="file"
                    onChange={onChangeMutifile}
                  />

                  <CardMedia className={classes.imageCloudPdfChoose} component="img" image={imageIcloud} />
                  <Box className={classes.boxwrapMargin}>
                    <label id="label-file-upload" htmlFor="input-file-upload">
                      <>
                        <Typography className={classes.chosseFilePdf}>Chọn file hoặc kéo thả vào màn hình</Typography>
                        <Typography className={classes.downloadFdf}>
                          Lưu ý: Tải file lên theo định dạng “Tên trạm + hạng mục thí nghiệm.pdf”
                        </Typography>
                      </>
                    </label>

                    <Typography className={classes.uploadDone}>
                      Đã tải lên :<Typography className={classes.fileDone}>{`${0} file`}</Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ textAlign: "center" }}>
                  <Button
                    onClick={onClickFileMuTi}
                    color="secondary"
                    variant="contained"
                    className={classes.buttonDownload}
                    endIcon={<UploadIcon />}
                  >
                    Đăng thêm dữ liệu
                  </Button>
                </Box>
              </form>
              <Box className={classes.fileFlex}>
                <CardMedia sx={{ width: 26, height: 30 }} component="img" image={imageUpdateDone} />
                <Box className={classes.flexone}>
                  <Box className={classes.linearProgress}>
                    <Typography>{valueInput.name}</Typography>

                    <Typography>
                      {
                        // TODO: get file size
                      }
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                  </Box>
                </Box>
              </Box>
              {openPopupMutiFile && (
                <Box className={classes.fileFlex}>
                  <CardMedia sx={{ width: 26, height: 30 }} component="img" image={imageUpdateDone} />
                  <Box className={classes.flexone}>
                    <Box className={classes.linearProgress}>
                      <Typography>{onSelecctFile.name}</Typography>
                      <Typography>
                        {
                          //TODO: get file size
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                    </Box>
                  </Box>
                </Box>
              )}
              <Box className={classes.button}>
                <Button
                  classes={{ root: classes.backgroundButton }}
                  className={classes.widthButton}
                  variant="contained"
                  onClick={handleUploadFilePdf}
                >
                  Lưu
                </Button>
                <Button onClick={onClose} className={classes.widthButton}>
                  Hủy
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default PostReport;

const useStyles = makeStyles(theme => ({
  paddingWidth: {
    padding: "0 150px",
  },
  colorIcon: {
    color: "#2E2E2E",
  },
  fileFlex: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  marginSelectChooseFile: {
    fontSize: 12,
    fontWeight: 600,
    color: "#2E2E2E",
  },
  flexone: {
    flex: 1,
    marginLeft: 16,
  },
  titlepdf: {
    paddingBottom: 70,
    textAlign: "center",
  },
  uploadFile: {
    color: "#2E2E2E",
    fontSize: 24,
  },
  widthButton: {
    width: 200,
    marginLeft: 12,
  },
  fileDone: {
    color: "#285AC6",
    marginLeft: 3,
  },
  boxwrapMargin: {
    marginLeft: 20,
  },
  handlerWidth: {
    width: 500,
  },
  uploadDone: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 12,
    display: "flex",
    alignItems: "center",
  },
  dotterBorder: {
    border: "1px dashed #CBCACA",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginBottom: 150,
    textAlign: "center",
  },
  linearProgress: {
    display: "flex",
    marginRight: 16,
    justifyContent: "space-between",
  },
  dooterChooseFile: {
    border: "1px dashed #CBCACA",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginBottom: 40,
  },
  imageCloud: {
    width: 48,
    height: 48,
    padding: "40px 0",
  },
  imageCloudPdf: {
    width: 48,
    height: 48,
    paddingTop: 60,
    paddingBottom: 30,
  },
  flexChoosefile: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
  },
  imageCloudPdfChoose: {
    width: 48,
    height: 48,
  },
  labelInput: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chosseFilePdf: {
    color: "#2E2E2E",
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 12,
  },
  downloadFdf: {
    color: "#FF2C28",
    fontSize: 12,
    fontWeight: 400,
  },
  buttonDownload: {
    textTransform: "capitalize",
    borderRadius: 10,
    margin: "40px 0",
    fontSize: 15,
    fontWeight: 600,
  },
  iconClose: {
    display: "flex",
    justifyContent: "flex-end",
  },
  backgroundButton: {
    backgroundColor: "#CAD6F1",
  },
  buttonSave: {
    width: 150,
    marginTop: 40,
    borderRadius: 10,
    fontSize: 15,
    fontWeight: 700,
  },
  iconFlex: {
    display: "flex",
    justifyContent: "flex-end",
  },
  root: {
    backgroundColor: "#CAD6F1",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: 90,
    marginBottom: 30,
  },
}));
