export const BASE_URL = "https://dldd.bglobalcorp.vn/";
// export const BASE_URL = "http://192.168.1.146:8081";
// export const BASE_URL = "http://192.168.1.64:8081/";

export const HEADER_DEFAULT = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const TIMEOUT = 60000;

export const STT_OK = 200;
export const STT_BAD_REQUEST = 400;
export const STT_UNAUTHORIZED = 401;
export const STT_FORBIDDEN = 403;
export const STT_NOT_FOUND = 404;
export const STT_INTERNAL_SERVER = 500;
export const STT_DUPLICATE_MESSAGE = 700;
export const STT_MAINTAIN = 503;

export const CHECK_LOGIN_API = "api/login";

export const USER_API = "api/user";

export const USER_API_ID = "api/user/{0}";

export const API_PROFILE = "api/user/{id}";

export const API_CHANGE_PASSWORD = `api/user/change_password`;

export const SUBSTATION = "api/substation";

export const SUBSTATION_CODE = "api/substation/{0}";

export const FILE = "api/export_report";

export const UPLOAD_REMIND = "api/upload/reminder";

export const UPLOAD_SUBSTATION = "api/upload/substation";

export const GANTT_CHAT = "api/reminder/list";

export const REMINDER_WITH_ID = "api/reminder/{0}";

export const API_GANTT_CHART_POPUP = "api/reminder/";

export const API_SEARCH_REMINDER = "api/substation";

export const API_ADD_REMINDER = "api/reminder";

export const API_DELETE = "api/reminder";

export const DETAILS_REMIND = "api/reminder";

export const RESET_PASSWORD = "api/user/{id}/reset-password";
