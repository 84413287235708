import * as React from "react";
import Dialog from "@mui/material/Dialog";
import imagePopup from "theme/img/IconConfirm.png";
import { Box } from "@mui/system";
import { Button, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ConfirmPopup from "./ConfirmPopup";
import { useState } from "react";

const PopupDeleteRemind = ({ onClose, open }) => {
  const classes = useStyles();
  const [confirm, setConfirm] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.poupDelete}>
          <CardMedia sx={{ width: "100px", margin: "auto", paddingTop: 2 }} component="img" src={imagePopup} />
          <Typography className={classes.titleDelete}>Bạn muốn hủy thao tác đang làm?</Typography>
          <Box className={classes.buttonDelete}>
            <Button onClick={onClose} className={classes.marginButton} variant="contained">
              Có
            </Button>
            <Button onClick={() => setConfirm(!confirm)} className={classes.marginButton} variant="text">
              Không
            </Button>
          </Box>
        </Box>
      </Dialog>
      <ConfirmPopup open={confirm} onClose={onClose} setConfirm={() => setConfirm(!confirm)} />
    </>
  );
};

export default PopupDeleteRemind;
const useStyles = makeStyles(theme => ({
  poupDelete: {
    minWidth: 500,
  },
  titleDelete: {
    color: "#2E2E2E",
    display: "flex",
    justifyContent: "center",
    padding: "20px 0 40px 0",
  },
  buttonDelete: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 35,
  },
  marginButton: {
    width: 150,
  },
}));
