import { Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import PasswordIcon from "@mui/icons-material/Password";
import { makeStyles } from "@material-ui/core";
import ChangePassword from "./ChangePassword";
import { useEffect } from "react";
import RemindAction from "redux/remind.redux";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const Profile = () => {
  const dataProfile = useSelector(state => state.remindRedux.dataSet);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [openChangPassword, setOpenChangePassword] = useState(false);

  useEffect(() => {
    dispatch(
      RemindAction.apiProfile({
        id: Cookies.get("accountId"),
      }),
    );
  }, []);

  return (
    <Container maxWidth={false}>
      <Typography variant="h5">Cá nhân</Typography>
      <Button
        classes={{ root: classes.borderButton }}
        onClick={() => setOpenChangePassword(!openChangPassword)}
        variant="contained"
        endIcon={<PasswordIcon />}
      >
        Đổi mật khẩu
      </Button>
      <Typography className={classes.nameProfile}>Tên người dùng</Typography>
      <Typography className={classes.subTitleProfile}>{dataProfile?.fullname}</Typography>
      <Typography className={classes.nameProfile}>Email hiện tại của bạn</Typography>
      <Typography className={classes.subTitleProfile}>{dataProfile?.username}</Typography>
      <Typography className={classes.nameProfile}>Số điện thoại</Typography>
      <Typography className={classes.subTitleProfile}>{dataProfile?.phone}</Typography>
      <Typography className={classes.nameProfile}>Chức vụ </Typography>
      <Typography className={classes.subTitleProfile}>{dataProfile?.role?.name}</Typography>
      {dataProfile?.role?.number === 1 && dataProfile?.role?.number === 2 ? (
        <>
          <Typography className={classes.nameProfile}>Phạm vi</Typography>
          <Typography className={classes.subTitleProfile}>{dataProfile?.role?.name}</Typography>
        </>
      ) : (
        <></>
      )}
      <ChangePassword onClose={() => setOpenChangePassword(!openChangPassword)} open={openChangPassword} />
    </Container>
  );
};

export default Profile;

const useStyles = makeStyles(theme => ({
  nameProfile: {
    fontSize: 15,
    fontWeight: 600,
    color: "#000000",
    padding: "5px 0",
  },
  subTitleProfile: {
    fontSize: 12,
    fontWeight: 600,
    color: "#7F7F80",
  },
  borderButton: {
    borderRadius: 10,
  },
}));
