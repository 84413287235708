import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../../theme/img/logo.png";
import React, { useState } from "react";
import { Typography, TextField, Button, Box, Link } from "@mui/material";
import { Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ForgotPass = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Sai định dạng Email").required("Vui lòng điền Email!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: value => {
      setOpen(true);
    },
  });

  return (
    <>
      <Box className={classes.logoImage} my={1}></Box>
      <Typography variant="h4" align="center" my={1}>
        Quên mật khẩu
      </Typography>
      <form>
        <Typography my={1}>Email</Typography>
        <TextField
          fullWidth
          my={1}
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Button color="primary" variant="contained" fullWidth my={1} onClick={formik.handleSubmit}>
          Xác nhận
        </Button>
        <Link href="/" underline="none" my={1}>
          Quay lại đăng nhập
        </Link>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
        classes={{
          paper: classes.dialog1,
        }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogImg}></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" variant="h6">
            <Typography style={{ fontWeight: "Bold" }}>Chúng tôi đã gửi Email khôi phục tài khoản tới bạn</Typography>
            <Typography style={{ fontWeight: "Bold" }}>Vui lòng kiểm tra mục thư đến hoặc hòm thư Spam</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" style={{ width: "200px" }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ForgotPass;

const useStyles = makeStyles(theme => ({
  logoImage: {
    backgroundImage: `url(${Logo})`,
    width: "100px",
    height: "100px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  formLogin: {
    width: "500px",
  },
}));
