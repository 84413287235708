import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SearchIcon from "@mui/icons-material/Search";
import {  makeStyles } from "@material-ui/core";
import BoltIcon from "@mui/icons-material/Bolt";
import PopupDeleteRemind from "./PopupAddRemind";
import { useDispatch, useSelector } from "react-redux";
import RemindAction from "redux/remind.redux";
import { getTimestamp } from "utils/date.utils";
import { getFilterArray } from "utils";
import { useDebounce } from "utils";

const AddReminder = ({ onClose, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFetch = useSelector(state => state.remindRedux.isSuccess);
  const searchApi = useSelector(state => state.remindRedux.data);
  const errApiAddremind = useSelector(state => state.remindRedux.error);

  const [resultSearch, setResultSearch] = useState();
  const [age, setAge] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [endTime, setEndTime] = useState(new Date());
  const [searchRemind, setSearchRemind] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [selectItem, setSelectItem] = useState();
  const [activeItem, setActiveItem] = useState();
  const handlerDebound = useDebounce(searchRemind, 500);

  const handleChange = event => {
    setAge(event.target.value);
  };

  useEffect(() => {
    dispatch(
      RemindAction.apiSearch({
        page: 1,
        limit: 100,
        key_value: searchRemind,
      }),
    );
  }, []);

  const onClickSave = () => {
    const checkTimeEnd = new Date(endTime).getMonth();
    const checkStartEnd = new Date(startTime).getMonth();
    if (checkStartEnd > checkTimeEnd) {
      alert("không thể chọn thời gian dự kiến nhỏ hơn thời gian hoàn thành !!!");
    }

    dispatch(
      RemindAction.addRemind({
        issue_id: age,
        substation_id: selectItem?.id,
        start_time: getTimestamp(startTime),
        estimate_time: getTimestamp(endTime),
        year_of_implement: startTime.getFullYear(),
      }),
    );
  };

  useEffect(() => {
    if (errApiAddremind) {
      alert("Trạm đã tồn tại vui lòng chọn trạm khác !!!");
      dispatch(RemindAction.resetRedux());
    }
  }, [errApiAddremind]);

  useEffect(() => {
    if (isFetch) {
      onClose();
      dispatch(RemindAction.resetRedux());
      setAge("");
      setActiveItem("");
    }
  }, [isFetch]);

  useEffect(() => {
    const result = getFilterArray(searchApi, handlerDebound);
    setResultSearch(result);
  }, [handlerDebound, searchApi]);

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xl">
      <Box className={classes.iconClose} onClick={onClose}>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography className={classes.titleAddRemind}>Thêm nhắc nhở</Typography>
      <Box className={classes.paddingRemind}>
        <Box className={classes.flexRemind}>
          <Box>
            <Typography className={classes.titleH2}>Lựa chọn hạng mục</Typography>
            <FormControl className={classes.widthSelect}>
              <InputLabel style={{ lineHeight: 1 }} id="demo-simple-select-label">
                Chọn loại hạng mục
              </InputLabel>
              <Select
                label="Chọn hạng mục"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                onChange={handleChange}
              >
                {/*<MenuItem value={3}>Xiết tiếp xúc theo kế hoạch</MenuItem>*/}
                {/*<MenuItem value={2}>Sửa chữa lớn</MenuItem>*/}
                {/*<MenuItem value={1}>Thay định kỳ</MenuItem>*/}
                {/*<MenuItem value={5}>Đầu tư xây dựng</MenuItem>*/}
                <MenuItem value={8}>Xiết tiếp xúc </MenuItem>
                <MenuItem value={4}>Thí nghiệm</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.timeCenter}>
            <Typography className={classes.titleH2}>Thời gian bắt đầu</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={startTime}
                inputFormat="MM/yyyy"
                minDate={new Date("2020-01-01")}
                onChange={newValue => {
                  setStartTime(newValue);
                }}
                InputProps={{
                  classes: {
                    root: classes.wdithDatePicker,
                  },
                }}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <Typography className={classes.titleH2}>Dự kiến hoàn thành</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={endTime}
                inputFormat="MM/yyyy"
                minDate={new Date("2020-01-01")}
                onChange={newValue => {
                  setEndTime(newValue);
                }}
                InputProps={{
                  classes: {
                    root: classes.wdithDatePicker,
                  },
                }}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Typography className={classes.titleH2}>Lựa chọn trạm </Typography>
        <TextField
          classes={{ root: classes.rootTextField }}
          fullWidth
          value={searchRemind}
          onChange={e => setSearchRemind(e.target.value)}
          placeholder="Tìm kiếm trạm tại đây"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box className={classes.boxSearch}>
          {resultSearch &&
            resultSearch.map((item, index) => {
              return (
                <List
                  className={activeItem === index ? classes.active : ""}
                  key={item.id}
                  component="nav"
                  aria-label="mailbox folders"
                >
                  <ListItem
                    button
                    onClick={() => {
                      setSelectItem(item);
                      setActiveItem(index);
                    }}
                  >
                    <IconButton style={{ color: "#FFD600" }}>
                      <BoltIcon />
                    </IconButton>
                    <Box className={classes.boxSearchFlex}>
                      <Typography className={classes.titleBoxSearch}>{item?.name}</Typography>
                      <Typography className={classes.desBoxSearch}>{item?.group?.name}</Typography>
                    </Box>
                  </ListItem>
                </List>
              );
            })}
        </Box>
        <Box className={classes.button}>
          <Button
            onClick={onClickSave}
            classes={{ root: activeItem + 1 ? <></> : classes.backgroundButton }}
            className={classes.widthButton}
            variant="contained"
          >
            Lưu
          </Button>
          <Button onClick={() => onClose()} className={classes.widthButton}>
            Hủy
          </Button>
        </Box>
      </Box>
      <PopupDeleteRemind open={openPopup} onClose={() => setOpenPopup(!openPopup)} />
    </Dialog>
  );
};

export default AddReminder;

const useStyles = makeStyles(theme => ({
  iconClose: {
    display: "flex",
    justifyContent: "flex-end",
  },
  titleAddRemind: {
    fontSize: 24,
    color: "#2E2E2E",
    fontWeight: 700,
    textAlign: "center",
  },
  flexRemind: {
    display: "flex",
  },
  paddingRemind: {
    padding: "20px 130px",
  },
  timeCenter: {
    margin: "0 60px",
  },
  active: {
    backgroundColor: "#ccc",
  },
  wdithDatePicker: {
    borderRadius: 10,
    width: 200,
  },
  titleH2: {
    fontSize: 12,
    fontWeight: 600,
    color: "#7F7F80",
    paddingTop: 31,
    paddingBottom: 12,
  },
  boxSearch: {
    border: "1px solid #E6E6E6",
    borderRadius: 10,
    marginTop: 12,
    height: 300,
    overflow: "auto",
  },
  boxSearchSub: {
    display: "flex",
    margin: "24px 30px",
  },
  titleBoxSearch: {
    fontSize: 15,
    fontWeight: 600,
    color: "#2E2E2E",
  },
  desBoxSearch: {
    fontSize: 12,
    fontWeight: 400,
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: 12,
    marginBottom: 30,
  },
  widthButton: {
    width: 200,
    marginLeft: 12,
  },
  boxSearchFlex: {
    flex: 1,
  },
  backgroundButton: {
    backgroundColor: "#CAD6F1",
    "&:hover": {
      backgroundColor: "#CAD6F1",
    },
  },
  rootTextField: {
    backgroundColor: "#F5F5F5",
    borderRadius: 15,
    [`& fieldset`]: {
      borderRadius: 15,
    },
  },
  widthSelect: {
    width: 250,
  },
}));
