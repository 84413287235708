import { createReducer, createActions } from "reduxsauce";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getRemindList: [],
  remindListSuccess: ["data"],
  remindListFailure: ["error"],
  apiProfile: ["data"],
  apiChangePassword: ["data"],
  conversationSet: ["data"],
  getGantChatApi: ["data"],
  exportFile: ["data"],
  getData: ["data"],
  remindDetails: ["data"],

  apiPopupGanttChart: ["data"],
  updateIssue: ["data"],
  editRemind: ["data"],
  deleteRemind: ["data"],

  apiSearch: ["data"],
  addRemind: ["data"],
  resetRedux: ["data"],

  remindSet: ["data"],
  resetPassword: ["data"],
});
export const RemindTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  data: null,
  isFetching: false,
  isSuccess: false,
  error: null,
  dataSet: null,
  gantChat: null,
  isSuccessDelete: false,
  dataGantt: null,
  dataExportFile: null,
  remindDetailsSuccess: null,
  dataEX: null,
  dateYearTightening: null,
  successful: false,
};
/* ------------- Reducers Waiting Consumer ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});
export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};
export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});
export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
  };
};

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  isSuccessDelete: false,
  error: null,
  dataExportFile: null,
});
/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_REMIND_LIST]: request,
  [Types.REMIND_LIST_SUCCESS]: success,
  [Types.REMIND_LIST_FAILURE]: failure,
  [Types.API_PROFILE]: request,
  [Types.API_CHANGE_PASSWORD]: request,
  [Types.CONVERSATION_SET]: set,
  [Types.GET_GANT_CHAT_API]: request,
  [Types.EXPORT_FILE]: request,
  [Types.RESET_PASSWORD]: request,

  [Types.API_POPUP_GANTT_CHART]: request,
  [Types.DELETE_REMIND]: request,

  [Types.EDIT_REMIND]: request,

  [Types.API_SEARCH]: request,
  [Types.ADD_REMIND]: request,
  [Types.REMIND_DETAILS]: request,

  [Types.GET_DATA]: set,

  [Types.UPDATE_ISSUE]: request,

  [Types.REMIND_SET]: set,

  [Types.RESET_REDUX]: reset,
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
