import { ApiConstant } from "../const";
import { createApiRegistration, createApiWithToken } from "../api";
import StringFormat from "string-format";

export const getListUserData = () => {
  return createApiWithToken().get(ApiConstant.USER_API);
};

export const postUser = data => createApiRegistration().post(ApiConstant.USER_API, data);

export const deleteUser = data => createApiRegistration().delete(StringFormat(ApiConstant.USER_API_ID, data.id));

export const updateUser = data => createApiWithToken().put(StringFormat(ApiConstant.USER_API_ID, data.id), data);
