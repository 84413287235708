import { call, put } from "redux-saga/effects";
import { FileService } from "../services";
import FileAction from "../redux/file.redux";

export function* getFile(action) {
  try {
    const { data } = action;

    let response = yield call(FileService.getFileService, data);

    let fileData = response.data;

    if (response.status === 200 && fileData) {
      yield put(
        FileAction.fileSet({
          isGetFileSuccess: true,
          data: fileData,
        }),
      );
    } else {
      yield put(FileAction.fileFailure(response.data));
    }
  } catch (error) {
    yield put(FileAction.fileFailure(error));
  }
}

export function* uploadFileRemind(action) {
  try {
    const { data } = action;
    let formData = new FormData();
    formData.append("file", data);
    formData.append("type", "xlsx");

    let response = yield call(FileService.uploadFileRemindService, formData);

    if (response.status === 200) {
      let responseData = response.data.data;
      yield put(
        FileAction.fileSet({
          responseWarn: responseData,
          uploadFileSuccess: true,
        }),
      );
    } else {
      yield put(
        FileAction.fileSet({
          errorPostFile: true,
        }),
      );
    }
  } catch (error) {
    yield put(FileAction.fileFailure(error));
  }
}

export function* uploadFileSubstation(action) {
  try {
    const { data } = action;
    let formData = new FormData();
    formData.append("file", data);
    formData.append("type", "xlsx");

    let response = yield call(FileService.uploadFileSubstationService, formData);
    if (response.status === 200) {
      let responseData = response.data.data;
      yield put(
        FileAction.fileSet({
          responseWarn: responseData,
          uploadFileSuccess: true,
        }),
      );
    } else {
      yield put(FileAction.fileFailure(response.data));
    }
  } catch (error) {
    yield put(FileAction.fileFailure(error));
  }
}
