import React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import { Button, CardMedia, CircularProgress, IconButton, Typography } from "@mui/material";
import imageIcloud from "../../../../theme/img/uploadCloud.png";
import CloseIcon from "@mui/icons-material/Close";
import imageBlue from "../../../../theme/img/FrameDownload.png";
import { useEffect, useState } from "react";
import { Backdrop, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FileAction from "redux/file.redux";
import { formatSizeUnits } from "utils/date.utils";

const PopupFdf = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const uploadFileSuccess = useSelector(state => state.fileRedux.uploadFileSuccess);
  const errorPostFile = useSelector(state => state.fileRedux.errorPostFile);

  const [valueFilePdf, setValueFilePdf] = useState("");
  const [opens, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeFile = e => {
    setValueFilePdf(e.target.files[0]);
  };

  const handleUploadRemind = () => {
    setOpen(!opens);
    dispatch(FileAction.uploadFileRemind(valueFilePdf));
  };

  const onClickCancel = () => {
    setValueFilePdf("");
    dispatch(FileAction.resetRedux());
  };

  useEffect(() => {
    if (uploadFileSuccess) {
      dispatch(
        FileAction.fileSet({
          uploadFileSuccess: false,
        }),
      );

      onClose();
      setValueFilePdf("");
      setOpen(false);
    }
  }, [uploadFileSuccess]);

  useEffect(() => {
    if (errorPostFile) {
      setOpen(!opens);
    }
  }, [errorPostFile]);

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xl">
      <Box className={classes.iconClose} onClick={onClose}>
        <IconButton>
          <CloseIcon classes={{ root: classes.colorIcon }} />
        </IconButton>
      </Box>
      <Box className={classes.boxPopupChooseFile}>
        {valueFilePdf ? (
          <Box>
            <Box className={classes.boxPopupChooseFile}>
              <Box className={classes.handlerWidth}>
                <Typography className={classes.titlepdf}>Đăng từ file Excel</Typography>
                <Box className={classes.dotterBorderSelect}>
                  <CardMedia className={classes.imageCloudPdf} component="img" image={imageIcloud} />
                  <Box className={classes.marginSelect}>
                    <Typography
                      className={classes.marginSelectChooseFile}
                    >{`Đã chọn file "${valueFilePdf.name}"`}</Typography>
                    {errorPostFile && (
                      <Typography className={classes.erroUpload}>sai định dạng vui lòng kiểm tra lại</Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.flexSelect}>
              <CardMedia className={classes.imageBlue} component="img" image={imageBlue} />
              <Box className={classes.flexSub}>
                <Typography className={classes.fileChoose}>{valueFilePdf.name}</Typography>
                <Typography className={classes.fileSize}>{formatSizeUnits(valueFilePdf.size)}</Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button className={classes.buttonSave} variant="contained" onClick={handleUploadRemind}>
                Lưu
              </Button>
              <Button onClick={onClickCancel} className={classes.buttonSave} variant="text">
                Hủy
              </Button>
            </Box>
          </Box>
        ) : (
          <Box className={classes.handlerWidth}>
            <Box className={classes.titlepdf}>
              <Typography className={classes.uploadFile}>Đăng từ file Excel</Typography>
            </Box>
            <form className={classes.dotterBorder} id="form-file-upload">
              <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={onChangeFile}
              />
              <label className={classes.labelInput} htmlFor="raised-button-file">
                <Box className={classes.imageCloud}>
                  <CardMedia component="img" image={imageIcloud} />
                </Box>
                <Typography className={classes.chosseFilePdf}>Chọn file hoặc kéo thả vào màn hình</Typography>
              </label>
            </form>
          </Box>
        )}
      </Box>
      <Backdrop className={classes.root} open={opens} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default PopupFdf;

const useStyles = makeStyles(theme => ({
  boxPopupfdf: {
    padding: "10px 150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxPopupChooseFile: {
    padding: "10px 100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileSize: {
    fontSize: 12,
  },
  flexSubTile: {
    display: "flex",
    justifyContent: "space-between",
  },
  colorIcon: {
    color: "#2E2E2E",
  },
  marginSelectChooseFile: {
    fontSize: 12,
    fontWeight: 600,
    color: "#2E2E2E",
  },
  titlepdf: {
    paddingBottom: 70,
    textAlign: "center",
  },
  uploadFile: {
    color: "#2E2E2E",
    fontSize: 24,
  },
  handlerWidth: {
    width: 500,
  },
  erroUpload: {
    color: "#FF2C28",
    fontSize: 12,
    fontWeight: 600,
  },
  dotterBorder: {
    border: "1px dashed #CBCACA",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginBottom: 150,
    textAlign: "center",
    cursor: "pointer",
  },
  dotterBorderSelect: {
    border: "1px dashed  #CBCACA",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginBottom: 40,
    display: "flex",
    padding: "20px 0 20px 20px",
  },
  flexSub: {
    flex: 1,
    marginLeft: 14,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imageCloud: {
    width: 48,
    height: 48,
    padding: "40px 0",
  },
  imageCloudPdf: {
    width: 48,
    height: 48,
  },
  labelInput: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  fileSelected: {
    display: "flex",
  },
  chosseFilePdf: {
    color: "#2E2E2E",
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 12,
  },
  downloadFdf: {
    color: "#285AC6",
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 20,
  },
  buttonDownload: {
    textTransform: "capitalize",
    borderRadius: 10,
    margin: "40px 0",
    fontSize: 15,
    fontWeight: 600,
  },
  iconClose: {
    display: "flex",
    justifyContent: "flex-end",
  },
  marginSelect: {
    marginLeft: 25,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 12,
  },
  imageBlue: {
    width: 25,
    height: 30,
  },
  flexSelect: {
    display: "flex",
    padding: "100px 120px 10px",
  },
  fileChoose: {
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.4)",
    fontWeight: 600,
  },
  buttonSave: {
    width: 150,
    marginTop: 40,
    borderRadius: 10,
    fontSize: 15,
    fontWeight: 700,
  },
  root: {
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
  },
}));
