import { createReducer, createActions } from "reduxsauce";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  createSubstation: ["data"],
  getListSubstation: ["data"],
  substationSuccess: ["data"],
  substationFailure: ["error"],
  substationSet: ["data"],
  deleteSubstation: ["data"],
  updateSubstation: ["data"],
});

export const SubstationTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  substationList: [],
  isFetching: false,
  isSuccess: false,
  isLoginSuccess: false,
  error: null,
  isCreateSuccess: false,
  isDeleteSuccess: false,
  isUpdateSuccess: false,
};

/* ------------- Reducers Waiting Consumer ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

export const set = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.CREATE_SUBSTATION]: request,
  [Types.GET_LIST_SUBSTATION]: request,
  [Types.DELETE_SUBSTATION]: request,
  [Types.UPDATE_SUBSTATION]: request,
  [Types.SUBSTATION_SUCCESS]: success,
  [Types.SUBSTATION_FAILURE]: failure,
  [Types.SUBSTATION_SET]: set,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
