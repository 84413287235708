import { takeLatest, all } from "redux-saga/effects";
import { RemindTypes } from "../redux/remind.redux";
import { LoginTypes } from "../redux/login.redux";
import { SubstationTypes } from "redux/substation.redux";
import { UserTypes } from "redux/user.redux";
import { FileTypes } from "redux/file.redux";

import {
  getGantChatApi,
  apiChangePassword,
  apiProflie,
  getRemindList,
  updateIssue,
  popupGanttChart,
  editRemind,
  deleteRemind,
  searchRemind,
  addRemind,
  remindDetails,
  exportFile,
  resetPassword,
} from "./remind.saga";
import { checkLogin } from "./login.saga";
import { createSubstation, deleteSubstation, getListSubstation, updateSubstation } from "./substation.saga";
import { getUserList, createUser, deleteUser, updateUser } from "./user.saga";
import { getFile, uploadFileRemind, uploadFileSubstation } from "./file.saga";

export default function* root() {
  yield all([
    takeLatest(RemindTypes.GET_REMIND_LIST, getRemindList),
    takeLatest(LoginTypes.CHECK_LOGIN, checkLogin),
    takeLatest(RemindTypes.API_PROFILE, apiProflie),
    takeLatest(RemindTypes.API_CHANGE_PASSWORD, apiChangePassword),
    takeLatest(SubstationTypes.CREATE_SUBSTATION, createSubstation),
    takeLatest(SubstationTypes.GET_LIST_SUBSTATION, getListSubstation),
    takeLatest(SubstationTypes.DELETE_SUBSTATION, deleteSubstation),
    takeLatest(SubstationTypes.UPDATE_SUBSTATION, updateSubstation),
    takeLatest(RemindTypes.GET_GANT_CHAT_API, getGantChatApi),

    takeLatest(RemindTypes.API_POPUP_GANTT_CHART, popupGanttChart),
    takeLatest(RemindTypes.EDIT_REMIND, editRemind),
    takeLatest(RemindTypes.DELETE_REMIND, deleteRemind),

    takeLatest(RemindTypes.API_SEARCH, searchRemind),
    takeLatest(RemindTypes.ADD_REMIND, addRemind),

    takeLatest(RemindTypes.UPDATE_ISSUE, updateIssue),
    takeLatest(RemindTypes.REMIND_DETAILS, remindDetails),
    takeLatest(RemindTypes.EXPORT_FILE, exportFile),
    takeLatest(RemindTypes.RESET_PASSWORD, resetPassword),

    takeLatest(UserTypes.GET_USER_LIST, getUserList),
    takeLatest(UserTypes.CREATE_USER, createUser),
    takeLatest(UserTypes.DELETE_USER, deleteUser),
    takeLatest(UserTypes.UPDATE_USER, updateUser),

    takeLatest(FileTypes.GET_FILE, getFile),
    takeLatest(FileTypes.UPLOAD_FILE_REMIND, uploadFileRemind),
    takeLatest(FileTypes.UPLOAD_FILE_SUBSTATION, uploadFileSubstation),
  ]);
}
