import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Button, Chip, FormControl, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "js-cookie";
import { AppConstant } from "const";
import { useDispatch } from "react-redux";
import RemindAction from "redux/remind.redux";
import { getTimestamp } from "utils/date.utils";
import { convertTimeStampToDate } from "utils/date.utils";
import { useSelector } from "react-redux";

const RepairPopup = ({ open, setOpenEachOne, onClose, newTimeArr, currentdata, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [valueDatePicker, setValueDatePicker] = useState(newTimeArr.status);
  const [startTime, setStartTime] = useState(new Date(newTimeArr.start_time * 1000));
  const [endTime, setEndTime] = useState(new Date(newTimeArr.estimate_time * 1000));
  const [valuePdf, setValuePdf] = useState();
  const [formData, setFormData] = useState("");
  const [note, setNote] = useState("");
  const roleId = Cookies.get(AppConstant.ROLE_ID);
  const isSuccess = useSelector(state => state.remindRedux.isSuccess);

  useEffect(() => {
    setStartTime(convertTimeStampToDate(newTimeArr?.start_time));
  }, [newTimeArr.start_time]);

  useEffect(() => {
    setEndTime(convertTimeStampToDate(newTimeArr?.estimate_time));
  }, [newTimeArr.estimate_time]);

  const handleChange = event => {
    if (valueDatePicker === "undefined") {
      return setValueDatePicker(newTimeArr.status);
    } else {
      setValueDatePicker(event.target.value);
    }
  };

  const handleNoteChange = event => {
    setNote(event.target.value);
  };

  const onClickHiddenPopup = () => {
    onClose();
    setOpenEachOne(false);
  };

  const onClickSave = () => {
    dispatch(
      RemindAction.editRemind({
        issue: {
          id: data.code,
          status: valueDatePicker === undefined ? newTimeArr.status : valueDatePicker,
          reminder_id: data.reminder_id,
          start_time: getTimestamp(startTime),
          estimate_time: getTimestamp(endTime),
          note: note,
        },
      }),
    );
  };

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess]);

  const onChangeFile = e => {
    const targetValue = e.target.value;
    const formdataFile = e.target.files[0];

    let formData = new FormData();
    formData.append("file", formdataFile);
    formData.append("type", "xlsx");
    setFormData(formData);
    setValuePdf(targetValue);
  };

  return (
    <Dialog
      onClose={() => setOpenEachOne(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      fullWidth
    >
      <Box className={classes.flexIcon}>
        <IconButton onClick={onClickHiddenPopup}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.flexPopup}>
        <IconButton onClick={() => setOpenEachOne(false)}>
          <ArrowBack />
        </IconButton>
        <Typography component="h2" className={classes.titileRepair}>
          {currentdata.substation.name}
        </Typography>
      </Box>
      <Box className={classes.wrapWidth}>
        {/*<Typography className={classes.typeWrap}>loại</Typography>*/}
        {/*<Chip size="small" classes={{ root: classes.root }} label="Thay định kỳ" />*/}
        <Box className={classes.flexListItem}>
          <Box>
            <Typography className={classes.titleListItem}>Trạng thái</Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={valueDatePicker}
                defaultValue={newTimeArr.status}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                SelectDisplayProps={{
                  style: { display: "flex", alignItems: "center" },
                }}
              >
                <MenuItem value={2} className={classes.select}>
                  <Box className={classes.flexPdf}>
                    <Box className={classes.statusOffline} />
                    Đang thực hiện
                  </Box>
                </MenuItem>
                <MenuItem value={3}>
                  <Box className={classes.flexPdf}>
                    <Box className={classes.statusOnline} />
                    Đã hoàn thành
                  </Box>
                </MenuItem>
                <MenuItem value={1}>
                  <Box className={classes.flexPdf}>
                    <Box className={classes.statusOffline1} />
                    Chưa thực hiện
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/*{roleId === "2" || roleId === "1" ? (*/}
            <>
              <Box className={classes.paddingItem}>
                <Typography className={classes.titleListItem}>Thời gian bắt đầu</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/yyyy"
                    value={startTime}
                    minDate={new Date("2017-01-01")}
                    onChange={newValue => {
                      setStartTime(newValue);
                    }}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <Typography className={classes.titleListItem}>Thời gian dự kiến hoàn thành</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/yyyy"
                    value={endTime}
                    minDate={new Date("2017-01-01")}
                    onChange={newValue => {
                      setEndTime(newValue);
                    }}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </>
          {/*) : (*/}
          {/*  <></>*/}
          {/*)}*/}
        </Box>
        <Box className={classes.flexPdf}>
          <Typography>{valuePdf}</Typography>
          {valuePdf ? (
            <IconButton onClick={() => setValuePdf("")}>
              <DeleteIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Box>
        <Typography className={classes.titleListItem}>Ghi chú</Typography>
        <TextField
          id="outlined-basic"
          fullWidth
          multiline
          rows={2}
          placeholder="Viết bình luận của bạn"
          name="code"
          note={note}
          onChange={handleNoteChange}
        />
        <Box textAlign="center" onClick={onClickSave}>
          <Button classes={{ root: classes.widthButton }} variant="contained">
            Lưu
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RepairPopup;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#2E2E2E",
    color: "#FFFFFF",
  },
  wrapWidth: {
    padding: "0 50px",
    [theme.breakpoints.down("740")]: {
      padding: "0 30px",
    },
  },
  flexPopup: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
  },
  flexIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  titileRepair: {
    fontSize: 24,
  },
  typeWrap: {
    color: "#7F7F80",
    fontSize: 12,
    margin: "32px 0 8px 0",
  },
  flexListItem: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("740")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  paddingItem: {
    padding: "0 40px",
    [theme.breakpoints.down("740")]: {
      padding: 0,
    },
  },

  titleListItem: {
    color: "#7F7F80",
    fontSize: 12,
    paddingBottom: 8,
    paddingTop: 10,
  },
  marginDefault: {
    margin: "auto",
  },
  widthButton: {
    minWidth: 185,
    marginTop: 40,
    marginBottom: 20,
    [theme.breakpoints.down("740")]: {
      width: "100%",
    },
  },
  statusOffline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#285AC6",
    marginRight: 6,
  },
  statusOffline1: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FF2C28",
    marginRight: 6,
  },
  statusOnline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#35CC3F",
    marginRight: 6,
  },
  select: {
    display: "flex",
  },
  flexPdf: {
    display: "flex",
    alignItems: "center",
  },
}));
