import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import format from "date-fns/format";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";

export default function TableData({ filterBy, setListCode }) {
  const dataGantChat = useSelector(state => state.remindRedux.dataGantt);
  const classes = useStyles();

  const [allList, setAllList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [checkAll, setCheckAll] = useState(true);

  const onSelectValue = row => {
    let tmp = [...exportList];
    if (tmp.find(item => item.substation_code === row.substation_code)) {
      tmp = tmp.filter(item => item.substation_code !== row.substation_code);
    } else {
      const subCheckArr = displayList.filter(item => item.substation_code === row.substation_code);
      subCheckArr.forEach(item =>  tmp.push({
        substation_code: item.substation_code,
        issue_id: item.issue_id,
      }));
    }
    setExportList(tmp);
    let codeArr = tmp.map(item => item.substation_code);
    setListCode(codeArr);
  };

  const onCheckAllHandle = () => {
    if (checkAll === true) {
      setExportList([]);
      setListCode([])
    }
    if (checkAll === false) {
      let tmp = displayList.map(item => {
        return { substation_code: item.substation_code, issue_id: item.issue_id };
      });
      setExportList(tmp);
    }
    setCheckAll(!checkAll);
  };

  const rewriteData = data => {
    let newMap = [];
    for (let index in data) {
      let group = data[index];
      for (let subStationIndex in group.substation) {
        let substation = group.substation[subStationIndex];
        for (let issueIndex in substation.issue) {
          let issue = substation.issue[issueIndex];

          let mapItem = {
            complete_time: issue.complete_time,
            estimate_time: issue.estimate_time,
            issue_id: issue.id,
            issue_name: issue.name,
            start_time: issue.start_time,
            issue_status: issue.status,
            substation_code: substation.code,
            substation_customer: substation.customers,
            substation_earthing: substation.earthing,
            substation_lightning_conductor: substation.lightning_conductor,
            substation_manufacturer: substation.manufacturer,
            substation_name: substation.name,
            substation_transformers: substation.transformers,
            group_id: group.id,
            group_name: group.name,
          };
          newMap.push(mapItem);
        }
      }
    }
    return newMap;
  };

  useEffect(() => {
    if (dataGantChat) {
      setAllList(rewriteData(dataGantChat));
      let tmp = rewriteData(dataGantChat).map(item => {
        return { substation_code: item.substation_code, issue_id: item.issue_id };
      });
      setExportList(tmp);
      let codeArr = tmp.map(item => item.substation_code);
      setListCode(codeArr);
    }
  }, [dataGantChat]);

  useEffect(() => {
    if (exportList.length === displayList.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [exportList, checkAll]);

  useEffect(() => {
    if (allList && allList.length > 0) {
      let tmpResult = [...allList];

      if (filterBy === 1) {
        tmpResult = [...allList].filter(item => item.issue_id !== 4);
      } else if (filterBy === 2) {
        tmpResult = [...allList].filter(item => item.issue_id === 4);
      }

      setDisplayList(tmpResult);

      let tmpArr = tmpResult.map(item => {
        return { substation_code: item.substation_code, issue_id: item.issue_id };
      });
      setExportList(tmpArr);
    } else {
      setDisplayList([]);
    }
  }, [filterBy, allList]);

  return (
    <Box>
      <Checkbox checked={checkAll} onChange={onCheckAllHandle} />
      Tất cả
      <TableContainer
        component={Paper}
        classes={{
          root: classes.tableContainer,
        }}
      >
        <Table sx={{ minWidth: 650, maxWidth: "100%", overFlow: "auto" }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">STT</TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Đội
              </TableCell>
              <TableCell align="center">Tên trạm</TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Mã trạm
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Hãng sản xuất
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Số KH
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Hộp phân dây & Chụp ảnh nhiệt ghíp
              </TableCell>
              <TableCell sx={{ minWidth: 50 }} align="center">
                XTX hòm công tơ
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Bắt đầu thực hiện
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Hoàn thành dự kiến
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Hoàn thành thực tế
              </TableCell>
              <TableCell sx={{ minWidth: 100 }} align="center">
                Trạng thái
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayList.length > 0 &&
              displayList.map((row, index) => {
                return (
                  <TableRow
                    key={`${row.id}-${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      onSelectValue(row);
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={Boolean(
                          exportList.find(
                            item => item.substation_code === row.substation_code
                          ),
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.group_name}</TableCell>
                    <TableCell align="center"> {row.substation_name} </TableCell>
                    <TableCell align="center">{row.substation_code}</TableCell>
                    <TableCell align="center">{row.substation_manufacturer}</TableCell>
                    <TableCell align="center">{row.substation_customer}</TableCell>
                    <TableCell align="center">{row.issue_id === 6 ? "x" : ""}</TableCell>
                    <TableCell align="center">{row.issue_id === 7 ? "x" : ""}</TableCell>
                    {/*<TableCell align="center">{row.issue_id === 3 ? "x" : ""}</TableCell>*/}
                    {/*<TableCell align="center">{row.issue_id === 5 ? "x" : ""}</TableCell>*/}
                    <TableCell align="center">{format(row.start_time * 1000, "dd/MM/yyyy")}</TableCell>
                    <TableCell align="center">{format(row.estimate_time * 1000, "dd/MM/yyyy")}</TableCell>
                    <TableCell align="center">{(row.complete_time === null || row.complete_time === 0) ? "" : format(row.complete_time * 1000, "dd/MM/yyyy")}</TableCell>
                    <TableCell align="center">
                      {row.issue_status === 3 ? "Đã hoàn thành" : ""}
                      {row.issue_status === 2 ? "Đang Thực hiện" : ""}
                      {row.issue_status === 1 ? "Chưa thực hiện" : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  tableContainer: {
    height: "calc(100vh - 200px)",

    overflow: "auto",
  },
  addSubButton: {
    marginRight: "10px",
  },

  dialog1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 70px",
  },
  diaAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
