import Login from "./pages/Login/Login";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Experiment from "./components/experiment/Experiment";
import ContactTightening from "pages/ContactTightening";
import ExportReport from "pages/ExportReport";
import ManagementSub from "pages/manager/ManagementSub";
import ManagementHR from "pages/manager/ManagementHR";
import Profile from "pages/profile/Index";
import { Box } from "@mui/system";
import "./style.css";
import TaskProvider from "context/task";
export default function App() {
  return (
    <TaskProvider>
      <Box className="App">
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route exact path="/" element={<ContactTightening />} />
            <Route index path="contact-tightening" element={<ContactTightening />} />
            <Route path="experiment" element={<Experiment />} />
            <Route path="export-report" element={<ExportReport />} />
            <Route path="management-hr" element={<ManagementHR />} />
            <Route path="management-substation" element={<ManagementSub />} />
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotPassword" element={<Login />} />
        </Routes>
      </Box>
    </TaskProvider>
  );
}
