import { createApiWithToken } from "api";
import { ApiConstant } from "const";
import StringFormat from "string-format";

export const createSubstation = data => createApiWithToken().post(ApiConstant.SUBSTATION, data);

export const getListSubstation = (data = { page: 1, limit: 2000 }) => {
  return createApiWithToken().get(ApiConstant.SUBSTATION, data);
};

export const deleteSubstation = data => createApiWithToken().delete(ApiConstant.SUBSTATION, data);

export const updateSubstation = data => createApiWithToken().put(StringFormat(ApiConstant.SUBSTATION_CODE,data.code),data)
