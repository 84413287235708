import { call, put, select } from "redux-saga/effects";
import { RemindService } from "../services";
import RemindSaga from "redux/remind.redux";
import { toSnake } from "utils";

export function* getRemindList(action) {
  try {
    const { data } = action;
    let response = yield call(RemindService.getGantChatApi, data);
  } catch (error) {}
}

export function* apiProflie(action) {
  const { data } = action;
  try {
    let response = yield call(RemindService.apiProfile, data);

    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          dataSet: response.data.data,
        }),
      );
    }
  } catch (error) {}
}

export function* apiChangePassword(action) {
  const { data } = action;
  try {
    let response = yield call(RemindService.apiChangePassword, data);
  } catch (error) {}
}

export function* getGantChatApi(action) {
  try {
    const { data } = action;
    let response = yield call(RemindService.getGantChatApi, data);

    if (response.status === 200) {
      yield put(
        RemindSaga.getData({
          dataGantt: response.data.data,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateIssue(action) {
  try {
    let response = yield call(RemindService.updateIssue, toSnake(action.data));
    if (response.status === 200) {
      yield put(
        RemindSaga.getData({
          isUpdateSuccess: true,
        }),
      );
    }
  } catch (error) {}
}

export function* popupGanttChart(action) {
  const { data } = action;
  try {
    let response = yield call(RemindService.apiPopupGanttChart, toSnake(data));

    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          dataSet: response.data.data,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* searchRemind(action) {
  try {
    let response = yield call(RemindService.searchRemind, toSnake(action.data));

    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          data: response.data.data,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* editRemind(action) {
  try {
    let response = yield call(RemindService.editRemind, toSnake(action.data));
    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          isSuccess: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteRemind(action) {
  const { data } = action;
  try {
    console.log(data)
    let response = yield call(RemindService.deleteRemind, {ids: data.ids.join(",")});
    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          isSuccessDelete: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addRemind(action) {
  const { data } = action;
  try {
    let response = yield call(RemindService.addRemind, toSnake(data));
    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          isSuccess: true,
        }),
      );
    }
    if (response.status === 400) {
      yield put(
        RemindSaga.conversationSet({
          error: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* remindDetails(action) {
  const { data } = action;
  try {
    let response = yield call(RemindService.remindDetails, toSnake({ ...data }));

    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          gantChat: response.data.data,
          remindDetailsSuccess: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* exportFile(action) {
  const { data } = action;
  try {
    let response = yield call(RemindService.exportFile, toSnake(data));
    if (response.status === 200) {
      yield put(
        RemindSaga.conversationSet({
          dataExportFile: response.data,
          dataEX: response.data,
          successful: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* resetPassword(action) {
  const { data } = action;
  try {
    let response = yield call(RemindService.resetPassword, toSnake(data));
    // if (response.status === 200) {
    //   yield put(
    //     RemindSaga.conversationSet({
    //       dataExportFile: response.data,
    //       dataEX: response.data,
    //       successful: true,
    //     }),
    //   );
    // }
  } catch (error) {
    console.log(error);
  }
}
