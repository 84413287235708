import Logo from "../../theme/img/logo.png";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Container, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ForgotPass from "./ForgotPass";
import LoginInput from "./LoginInput";
import { hasLogin } from "utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Login = () => {
  const classes = useStyles();
  const isLoginSuccess = useSelector(state => state.loginRedux.isLoginSuccess);

  const navigate = useNavigate();

  useEffect(() => {
    if (hasLogin()) {
      navigate("/", {
        replace: true,
      });
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    if (isLoginSuccess) {
      window.location.reload();
    }
  }, [isLoginSuccess]);

  return (
    <Container
      classes={{
        root: classes.container,
      }}
      maxWidth={false}
    >
      <Box className={classes.loginContain}>
        <Routes>
          <Route path="/" element={<LoginInput />} />
          <Route path="/forgotPassword" element={<ForgotPass />} />
        </Routes>
      </Box>
    </Container>
  );
};

export default Login;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      width: "100vw",
      height: "100vh",
      paddding: "0 20px",
    },
  },

  loginContain: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  logoImage: {
    backgroundImage: `url(${Logo})`,
    width: "100px",
    height: "100px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
}));
