import { ApiConstant } from "../const";
import { API_CONFIG_REG, createApiRegistration } from "../api";

export const getFileService = data => createApiRegistration().get(ApiConstant.FILE, data);

export const uploadFileRemindService = data => {
  return createApiRegistration().post(ApiConstant.UPLOAD_REMIND, data);
};

export const uploadFileSubstationService = data => {
  return createApiRegistration({
    ...API_CONFIG_REG,
    headers: {
      ...API_CONFIG_REG.headers,
      Accept: "text/xml",
    },
  }).post(ApiConstant.UPLOAD_SUBSTATION, data);
};
