import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch } from "react-redux";
import TableData from "./profile/TableExportReport";
import RemindAction from "redux/remind.redux";
import { saveAs } from "file-saver";
import { RemindService } from "../services";

const ExportReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [datePicker, setDatePicker] = useState(new Date());
  const [listCode, setListCode] = useState([]);

  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    type: 0,
    status: 4,
    group_id: null,
  });
  const [filterBy, setFilterBy] = useState(4);

  const onChangeFilter = event => {
    let currentType = event.target.value;
    setFilterBy(currentType);
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const onChageValue = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const onClickExpostFile = async () => {
    const data = {
      export_f: 1,
      estimate_year: datePicker.getFullYear(),
      type: values.type,
      substation_codes: listCode,
      group_id: values.group_id === 0 ? null : values.group_id,
      status: values.status
    };
    setOpen(true);
    let fileResponse = await RemindService.exportFile(data);
    setOpen(false);
    saveAs(new Blob([fileResponse.data]), "Kế hoạch xiết tiếp xúc.xlsx");
  };

  useEffect(() => {
    dispatch(
      RemindAction.getGantChatApi({
        ...values,
        estimate_year: datePicker.getFullYear(),
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      RemindAction.getGantChatApi({
        status: values.status,
        type: values.type,
        estimate_year: datePicker.getFullYear(),
        group_id: values.group_id === 0 ? null : values.group_id,
      }),
    );
  }, [values.type, datePicker, values.status, values.group_id]);

  return (
    <Container maxWidth={false}>
      <Typography variant="h5">Xuất báo cáo</Typography>
      <Box>
        <FormControl sx={{ minWidth: 120, marginRight: 5 }}>
          <Select defaultValue={0} displayEmpty name="type" value={values.type} onChange={onChageValue}>
            <MenuItem value={0}>Hạng mục</MenuItem>
            <MenuItem value={1}>Xiết tiếp xúc</MenuItem>
            <MenuItem value={2}>Thí nghiệm</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120, marginRight: 5 }}>
          <Select defaultValue={"Lựa chọn"} name="status" value={values.status} displayEmpty onChange={onChageValue}>
            <MenuItem value={0}>Toàn bộ trạng thái</MenuItem>
            <MenuItem value={1}>
              <Box className={classes.flexPdf}>
                <Box className={classes.unfulfilled} />
                Chưa thực hiện
              </Box>
            </MenuItem>
            <MenuItem value={2}>
              <Box className={classes.flexPdf}>
                <Box className={classes.statusOffline} />
                Đang thực hiện
              </Box>
            </MenuItem>
            {/*<MenuItem value={3}>*/}
            {/*  <Box className={classes.flexPdf}>*/}
            {/*    <Box className={classes.statusOnline} />*/}
            {/*    Đã hoàn thành*/}
            {/*  </Box>*/}
            {/*</MenuItem>*/}
            <MenuItem value={4}>
              <Box className={classes.flexPdf}>
                <Box className={classes.unfulfilled} />
                Chưa hoàn thành
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120, marginRight: 5 }}>
          <Select defaultValue={null} displayEmpty name="group_id" value={values.group_id} onChange={onChageValue}>
            <MenuItem value={null}>Tất cả đội</MenuItem>
            <MenuItem value={1}>Đội 1</MenuItem>
            <MenuItem value={2}>Đội 2</MenuItem>
            <MenuItem value={3}>Đội 3</MenuItem>
            <MenuItem value={4}>Đội 4</MenuItem>
            <MenuItem value={5}>Đội 5</MenuItem>
            <MenuItem value={9}>Đội F9</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year"]}
            label="Năm dự kiến"
            openTo="year"
            value={datePicker}
            onChange={newValue => {
              setDatePicker(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                helperText={null}
                classes={{
                  root: classes.filterInfo,
                }}
                className="filterInfo"
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Button
        classes={{
          root: classes.addSubButton,
        }}
        onClick={onClickExpostFile}
        color="secondary"
        variant="contained"
        type="submit"
        endIcon={<Download />}
      >
        Xuất file Excel
      </Button>
      <TableData filterBy={values} setListCode={setListCode} />
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default ExportReport;

const useStyles = makeStyles(theme => ({
  filterInfo: {
    width: "150px",
  },
  flexPdf: {
    display: "flex",
    alignItems: "center",
  },
  statusOffline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#285AC6",
    marginRight: 6,
  },
  unfulfilled: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FF2C28",
    marginRight: 6,
  },
  statusOnline: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#35CC3F",
    marginRight: 6,
  },
  addSubButton: {
    borderRadius: 10,
  },
}));
