import React from "react";
import { Box, Drawer } from "@mui/material";
import SideBarContent from "./SideBarContent";
import { makeStyles } from "@material-ui/core";
export const drawerWidth = "250px";

export default function Sidebar({ window }) {
  const classes = useStyles();

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
        }}
      >
        <Drawer variant="permanent" className={classes.containerSideBarContent} open>
          <SideBarContent />
        </Drawer>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  containerSideBarContent: {
    display: "block",

    "& .MuiDrawer-paper": {
      boxSizing: "border-box",

      backgroundColor: "#285ac6",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
